// P0002 • Cardlet
import React from 'react';
import Image from 'components/Image';


const Cardlet = ({ title, subhead, thumbnailURL, onClick }) => {
  return (
    <div className="cardlet flex space-between align-items-center cursor-pointer" onClick={onClick}>
      <div className="flex column cardlet-text-content">
        <span className="cardlet-title pst-h8">{ title }</span>
        <span className="cardlet-subhead pst-ctrl-micro-strong-caps greyscale-2">{subhead}</span>
      </div>
      {thumbnailURL && <div className="cardlet-thumbnail-img" >
        <Image src={thumbnailURL} size={56} mode="crop" />
      </div>}
    </div>
  );
};

Cardlet.propTypes = {
  title: YummlyPropTypes.string,
  subhead: YummlyPropTypes.string,
  thumbnailURL: YummlyPropTypes.string,
  onClick: YummlyPropTypes.func,
};

export default Cardlet;