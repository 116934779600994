import React, { useState } from 'react';

import { deleteMeals, toggleScheduleModal } from 'actions/MealPlanning';
import { connect } from 'react-redux';
import { str } from 'constants/MealPlanning';
import StickyAction from 'components/Pasta/StickyAction';
import ScheduleRecipeModalSelectRecipeScene from 'components/ScheduleRecipeModalSelectRecipeScene/ScheduleRecipeModalSelectRecipeScene';
import ScheduleRecipeModalSelectCollectionScene from 'components/ScheduleRecipeModalSelectCollectionScene/ScheduleRecipeModalSelectCollectionScene';
import { updateSelectedCollectionToSchedule, updateSelectedRecipesToSchedule, scheduleSelectedRecipes } from 'actions/MealPlanning';
import MealPlanModal from 'components/MealPlanModal/MealPlanModal';

const SCENES = {
  SELECT_COLLECTION: {
    ID: 'SELECT_COLLECTION',
    TITLE: str('addSavedRecipe'),
    SUBHEADING: str('browseYourCollections'),
  },
  SELECT_RECIPES_IN_COLLECTION: {
    ID: 'SELECT_RECIPES_IN_COLLECTION',
    TITLE: str('allSavedRecipes'),
    SUBHEADING: str('selectRecipesToAdd'),
  },
};

const MealPlannerScheduleRecipeModal = (props) => {
  const [scene, setScene] = useState(SCENES.SELECT_COLLECTION);

  function onClickDone() {
    props.scheduleSelectedRecipes();
    props.toggleScheduleModal(false);
  }

  function onCollectionClick({ friendlyName, url }) {
    const newScene = {
      ...SCENES.SELECT_RECIPES_IN_COLLECTION,
      TITLE: friendlyName,
    };
    setScene(newScene);
    props.updateSelectedCollectionToSchedule(url);
    props.updateSelectedRecipesToSchedule([]);
  }

  function onBackClick() {
    const newScene = SCENES.SELECT_COLLECTION;
    setScene(newScene);
  }


  const renderScene = ({ onCollectionClick }) => {
    switch (scene.ID) {
      case SCENES.SELECT_COLLECTION.ID:
        return <ScheduleRecipeModalSelectCollectionScene
          onCollectionClick={onCollectionClick}
        />;
      case SCENES.SELECT_RECIPES_IN_COLLECTION.ID:
        return <ScheduleRecipeModalSelectRecipeScene/>;
    }
  };

  return (
    <MealPlanModal
      title={scene.TITLE}
      subheading={scene.SUBHEADING}
      onBackClick={scene.ID === SCENES.SELECT_RECIPES_IN_COLLECTION.ID && onBackClick}
    >
      <>
        {renderScene({
          onCollectionClick,
        })}
        <StickyAction
          actionLabel={str('done')}
          onClick={onClickDone}
        />
      </>
    </MealPlanModal>
  );
};


MealPlannerScheduleRecipeModal.propTypes = {
  toggleScheduleModal: YummlyPropTypes.action,
  updateSelectedCollectionToSchedule: YummlyPropTypes.action,
  updateSelectedRecipesToSchedule: YummlyPropTypes.action,
  scheduleSelectedRecipes: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    myCollections: state.myCollections,
  };
};

const mapDispatchToProps = {
  deleteMeals,
  toggleScheduleModal,
  scheduleSelectedRecipes,
  updateSelectedCollectionToSchedule,
  updateSelectedRecipesToSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerScheduleRecipeModal);
