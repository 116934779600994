import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStringFunction } from 'util/localStrings';
import { bindInstance } from 'util/dev';
import classNames from 'util/classNames';
import { CATEGORY_KEY, CATEGORY_ID_KEY, DEFAULT_CATEGORY } from 'util/shoppingList';
import { deepProperty } from 'util/object';
import Button from 'components/Button';
import { icons } from 'constants/FontFaceCharacters';
import { ITEM_INCOMPLETE, ITEM_COMPLETED } from 'constants/index';
import { clearShoppingModal, updateItemCategory, getShoppingListScreenType } from 'actions/ShoppingList';
import { logTrackingEvents } from 'actions/Tracking';
import { ingredientCategoriesSelector } from 'selectors/metadata';
import { logEvent } from 'actions/mixpanel';
import { TRACKING } from 'constants/Tracking';
import { logDDEEvent } from 'actions/DDE';
import { firstCap } from 'util/string';

const str = getStringFunction(
  [
    {
      id: `shoppingListCategorySelectModal.title`,
      defaultMessage: 'Choose a category below',
    },
    {
      id: `shoppingListCategorySelectModal.confirm`,
      defaultMessage: 'Save',
    },
    {
      id: `shoppingListCategorySelectModal.cancel`,
      defaultMessage: 'Cancel',
    },
    {
      id: `shoppingListCategorySelectModal.notFound`,
      defaultMessage: 'WE COULD NOT FIND YOUR ITEM',
    },
  ],
  'shoppingListCategorySelectModal',
);

class ShoppingListCategorySelectModal extends Component {
  constructor(props) {
    super(props);
    const firstItem = this.getFirstItem();
    const originalCategory = {
      categoryName: firstItem[CATEGORY_KEY] || '',
      categoryId: firstItem[CATEGORY_ID_KEY] || '',
    };
    bindInstance(this, {
      initialState: {
        originalCategory,
        selectedCategory: originalCategory,
      },
    });
  }

  onConfirmClick() {
    const { item, mixpanelEvents, DDEEvents, newIngredient, clearShoppingModal, updateItemCategory, logTrackingEvents } = this.props;

    const editedCategoryName = deepProperty(this.state, 'selectedCategory.categoryName');
    if (editedCategoryName) {
      const itemArray = Array.isArray(item)
        ? item
        : [item];

      itemArray.forEach(item => {
        const { shoppingList: { viewType } } = this.props;
        const mpProps = {};
        const description = firstCap(item['description']);

        if (item.status === ITEM_INCOMPLETE) {
          mpProps['Item Unchecked'] = description;
        } else if (item.status === ITEM_COMPLETED) {
          mpProps['Item Checked'] = description;
        }

        // Update Mixpanel Category to option selected
        if (mixpanelEvents && mixpanelEvents[0]) {
          mixpanelEvents[0].properties['Category'] = this.state.selectedCategory.categoryName;
        }

        logTrackingEvents({
          mixpanelEvents,
          DDEEvents,
        });

        // edit category
        if (!newIngredient) {
          this.props.logMixpanelEvent(TRACKING.SHOPPING_LIST_ITEM_ACTIONS, {
            'Category Edited': editedCategoryName,
            'Item Name': description,
            'Shopping List Screen Type': getShoppingListScreenType(viewType),
            ...mpProps,
          });
        }

        updateItemCategory(item, this.state.selectedCategory, newIngredient);
      });
    }
    clearShoppingModal();

  }

  onSelectCategory(e) {
    const { categoryName, categoryId } = deepProperty(e, 'currentTarget.dataset') || {};
    this.setState({
      selectedCategory: {
        categoryName,
        categoryId,
      },
    });
  }

  getFirstItem() {
    const item = this.props.item;
    return (
      Array.isArray(item)
        ? item[0]
        : item
    ) || {};
  }

  closeModal() {
    this.props.clearShoppingModal();
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
      'Prompt Type': TRACKING.CONFIRM_LIST_DELETE,
    });
  }

  render() {
    const item = this.getFirstItem();
    const { itemCategories = [] } = this.props;
    const { originalCategory, selectedCategory } = this.state;
    const currentId = originalCategory.categoryId;

    const categories = [...itemCategories].sort(item => {
      return item.categoryId === currentId
        ? -1
        : 1;
    });

    return (
      <div className={`shopping-list-modal modal-update-category`}>
        <div className="modal-body-wrapper">
          { item[CATEGORY_KEY] === DEFAULT_CATEGORY && <div className="modal-title micro-caps greyscale-3">{str('notFound')}</div> }
          <h3 className="modal-title h4-text">{str(`title`)}</h3>
          <div className="category-list-wrapper">
            <ul className="category-list">
              {categories.map(({ category, categoryId }) => {
                const id = selectedCategory.categoryId || currentId;
                const isActive = id === categoryId;
                const cls = classNames({
                  'p3-text font-bold': true,
                  active: isActive,
                });

                return (
                  <li
                    key={categoryId}
                    data-category-name={category}
                    data-category-id={categoryId}
                    className={cls}
                    onClick={this.onSelectCategory}
                  >
                    <span>{category}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <Button className="btn-primary confirm-btn" onClick={this.onConfirmClick}>
            { str('confirm') }
          </Button>
          <Button className="btn-secondary cancel-btn" onClick={this.closeModal}>
            { str('cancel') }
          </Button>
        </div>
        <span className="y-icon close-btn" data-icon={icons.xLarge} role="button" onClick={this.closeModal}/>
      </div>
    );
  }
}

ShoppingListCategorySelectModal.propTypes = {
  clearShoppingModal: YummlyPropTypes.action,
  itemCategories: YummlyPropTypes.itemCategories,
  updateItemCategory: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  logTrackingEvents: YummlyPropTypes.action,
  shoppingList: YummlyPropTypes.shoppingList,
  item: YummlyPropTypes.shoppingItem,
  mixpanelEvents: YummlyPropTypes.arrayOfObjects,
  DDEEvents: YummlyPropTypes.arrayOfObjects,
  newIngredient: YummlyPropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    itemCategories: ingredientCategoriesSelector(state),
    shoppingList: state.shoppingList,
  };
};

const mapDispatchToProps = {
  clearShoppingModal,
  updateItemCategory,
  logMixpanelEvent: logEvent,
  logDDEEvent,
  logTrackingEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListCategorySelectModal);
