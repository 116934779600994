import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import focusInput from 'util/focusInput';
import { bindInstance } from 'util/dev';
import classNames from 'util/classNames';
import BigModalMapping from 'bundles/modals/BigModalMapping';
import { deepProperty } from 'util/object';
import { URB_PAGE } from 'util/location';
import { VIRTUAL_PANTRY_PROMO_KEY } from 'constants/BigModalKeys';

class BigModal extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this, {
      initialState: {
        animating: false,
      },
    });
  }

  handleClick(e) {
    const element = e && e.target;
    const tag = (element && element.tagName) || '';
    const isCheckboxDiv = element && element.classList.contains('checkbox-style');
    const input = document.querySelector('.new-login-email input');

    if (
      input &&
      !input.value &&
      !(/a|button|input/i.test(tag) || isCheckboxDiv) &&
      !this.state.animating
    ) {
      this.setState({
        animating: true,
      });
      setTimeout(() => {
        this.setState({
          animating: false,
        });
        focusInput(input);
      }, 600);
    }
  }

  render() {
    const { pageLocations, modalProps, modalType, location, params } = this.props;

    if (pageLocations && pageLocations[URB_PAGE]) {
      return null;
    }

    if (modalType === VIRTUAL_PANTRY_PROMO_KEY) {
      return null;
    }

    const cls = classNames({
      'big-modal': true,
      shown: true, // this.state.shown,
      animating: this.state.animating,
    });

    const passedInClasses = modalProps.wrapperClasses || '';
    const wrapperClasses = classNames({
      'modal-wrapper': true,
      [passedInClasses]: true,
    });
    const ChildComponent = BigModalMapping[modalType];

    if (!ChildComponent) {
      return null;
    }

    return (
      <div className={cls} onClick={this.handleClick}>
        <div className={wrapperClasses}>
          <ChildComponent {...modalProps} location={location} params={params} />
        </div>
      </div>
    );
  }
}

BigModal.propTypes = {
  wrapperClasses: YummlyPropTypes.string,
  modalType: YummlyPropTypes.modalType,
  modalProps: YummlyPropTypes.modalProps,
  location: YummlyPropTypes.location,
  params: YummlyPropTypes.params,
  pageLocations: YummlyPropTypes.pageLocations,
};

const mapStateToProps = (state) => {
  return {
    modalType: deepProperty(state, 'app.showModal.modalType', null),
    modalProps: deepProperty(state, 'app.showModal.modalProps', {}),
  };
};

export default connect(mapStateToProps)(withRouter(BigModal));
