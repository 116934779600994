import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from 'components/Pasta/Icon';
import { toggleManualEntryModal, scrapeEntryUrl, addMeals, updateMealPlanItem, clearPreviewCard, updateSelectedRecipesToSchedule, scheduleSelectedRecipes } from 'actions/MealPlanning';
import { bindInstance } from 'util/dev';
import classNames from 'util/classNames';
import { deepProperty } from 'util/object';
import { convertTotalTime } from 'util/number';
import Spinner from 'components/Spinner';
import Image from 'components/Image';
import { MANUAL_ENTRY_ADD_TYPE, ITEM_TYPE_KEY, RECIPE_ID_KEY, RECIPE_DATA_KEY } from 'constants/MealPlanning';

const LINKED_ENTRY_TYPE = 'linked';
const MY_RECIPE_ENTRY_TYPE = 'myRecipe';
const MANUAL_ENTRY_TYPE = 'user-entry';

class MealPlannerManualEntryModal extends Component {
  constructor(props) {
    super(props);
    let url = '', title = '', note = '', entryType = LINKED_ENTRY_TYPE;
    const meal = props.meal;

    if (meal) {
      url = meal.url || '';
      title = meal.title || '';
      note = meal.note || '';
      if (!meal.url) {
        entryType = MY_RECIPE_ENTRY_TYPE;
      }
    }

    bindInstance(this, {
      initialState: {
        entryType,
        url,
        title,
        note,
      },
      debounce: {
        scrapeUrl: 1500,
      },
    });

    this.scraper = null;
  }

  setEntryTypeRecipe() {
    this.setState({
      entryType: MY_RECIPE_ENTRY_TYPE,
    });
  }

  setEntryTypeLinked() {
    this.setState({
      entryType: LINKED_ENTRY_TYPE,
    });
  }

  closeModal() {
    this.props.toggleManualEntryModal(false);
    this.props.clearPreviewCard();
  }

  changeUrl(e) {
    const url = e.target.value;
    if (this.scraper) {
      clearTimeout(this.scraper);
    }

    if (url !== this.state.url) {
      this.scraper = setTimeout(() => {
        this.scrapeUrl(url);
      }, 500);
    }

    this.setState({
      url,
    });
  }

  changeNote(e) {
    this.setState({
      note: e.target.value,
    });
  }

  changeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  linkSubmit(e) {
    e.preventDefault();

    const meal = {
      ...this.props.meal,
    } || {};

    meal.url = this.state.url;
    meal[ITEM_TYPE_KEY] = MANUAL_ENTRY_TYPE;
    const recipe = deepProperty(this.props.previewCardRecipe, 'recipeInfo.recipe');

    if (recipe) {
      meal[RECIPE_ID_KEY] = recipe.versionId;
    } else {
      meal[RECIPE_ID_KEY] = null;
      meal[RECIPE_DATA_KEY] = null;
    }

    if (this.props.meal) {
      this.props.updateMealPlanItem(meal, {
        manualEntryEdit: true,
      });
    } else {
      this.props.addMeals({
        meals: [meal],
        trackingProps: {
          mpAddType: MANUAL_ENTRY_ADD_TYPE,
          ddeAddType: MANUAL_ENTRY_ADD_TYPE,
        },
        trayAnimation: false,
      });
    }

    this.closeModal();
  }

  recipeSubmit(e) {
    e.preventDefault();
    const meal = {
      ...this.props.meal,
    } || {};

    meal.title = this.state.title;
    meal.note = this.state.note;
    meal[ITEM_TYPE_KEY] = MANUAL_ENTRY_TYPE;
    meal.url = null;
    meal[RECIPE_ID_KEY] = null;

    if (this.props.meal) {
      this.props.updateMealPlanItem(meal, {
        manualEntryEdit: true,
      });
    } else {
      this.props.addMeals({
        meals: [meal],
        trackingProps: {
          mpAddType: MANUAL_ENTRY_ADD_TYPE,
          ddeAddType: MANUAL_ENTRY_ADD_TYPE,
        },
        trayAnimation: false,
      }).then((resp) => {
        const toSchedule = true;
        if (toSchedule) {
          const createdItem = resp?.items?.length && resp.items[0];
          if (createdItem) {
            this.props.updateSelectedRecipesToSchedule([createdItem]);
            this.props.scheduleSelectedRecipes();
          }
        }
      });
    }

    this.closeModal();
  }

  scrapeUrl(url) {
    this.props.scrapeEntryUrl(url);
  }

  render() {
    const linkedEntryClasses = classNames({
      "entry-type font-bold p3-text linked-entry": true,
      active: this.state.entryType === LINKED_ENTRY_TYPE,
    });

    const recipeEntryClasses = classNames({
      "entry-type font-bold p3-text recipe-entry": true,
      active: this.state.entryType === MY_RECIPE_ENTRY_TYPE,
    });

    const {
      previewCardError,
      previewCardFetching,
      previewCardRecipe,
    } = this.props;
    let recipeTime;
    let preview = null;

    if (previewCardRecipe) {
      const timeSec = deepProperty(previewCardRecipe, 'recipeInfo.recipe.totalTimeSec');
      recipeTime = convertTotalTime(timeSec);
    }

    const classes = classNames({
      'meal-planner-manual-entry-modal': true,
      'blank-space': !previewCardFetching && !previewCardRecipe && !previewCardError,
    });

    if (previewCardFetching) {
      preview = <Spinner mode="light" />;
    } else if (previewCardError === '200') {
      preview = (
        <div className="recipe-not-found">
          <p className="not-found-title font-bold p3-text greyscale-1">{'Add to Meal Plan'}</p>
          <p className="not-found-description font-normal p3-text greyscale-1">{'Click below to save this link. Open your Shopping List to add ingredients.'}</p>
        </div>
      );
    } else if (previewCardRecipe) {
      const recipe = deepProperty(previewCardRecipe, 'recipeInfo.recipe');
      preview = (
        <div className="preview-card">
          <Image className="preview-card-image" src={recipe.image} height={160} width={160} />
          <div className="preview-card-content">
            <p className="preview-card-name font-bold primary-dark h4-text">{recipe.name}</p>
            <div className="preview-card-description">
              <span className="preview-card-ingredients font-normal greyscale-1 p3-text">{`${deepProperty(recipe, 'ingredientLines.imperial', []).length} Ingredients`}</span>
              <span className="preview-card-time font-normal greyscale-1 p3-text">{`${recipeTime.value} ${recipeTime.unit}`}</span>
            </div>
          </div>
        </div>
      );
    }
    const disableSaveLink = !(this.state.url && (previewCardError === '200' || previewCardRecipe));
    const disableSaveTitle = !this.state.title;

    return (
      <div className={classes}>
        <Icon className="close-modal" iconName="xSmall" role="button" onClick={this.closeModal} title={'Close Prompt'}/>
        <div className="manual-entry-title h4-text font-bold primary-dark">
          {'Add Custom Entry'}
        </div>
        <section className="manual-entry-types">
          <div className={linkedEntryClasses} role="button" onClick={this.setEntryTypeLinked}>{'Link a Recipe'}</div>
          <div className={recipeEntryClasses} role="button" onClick={this.setEntryTypeRecipe}>{'Type a Recipe'}</div>
        </section>
        { this.state.entryType === LINKED_ENTRY_TYPE &&
          <form onSubmit={this.linkSubmit} className="linked-entry-form">
            <input type="text" className="text-input recipe-link" value={this.state.url} onChange={this.changeUrl} placeholder={'Add a Link'} id="manual-entry-link"/>
            <label htmlFor="manual-entry-link" className="manual-entry-label micro-caps font-bold link-label">{'Recipe Link'}</label>
            { Boolean(this.state.url?.length) && previewCardError && previewCardError !== '200' && <p className="font-bold error-message link-input-error manual-entry-label micro-caps">{'Please Enter a valid link'}</p>}
            {preview}
            <input type="submit" value="Save" className="submit-button btn-primary" disabled={disableSaveLink} />
          </form>
        }
        { this.state.entryType === MY_RECIPE_ENTRY_TYPE &&
          <React.Fragment>
            <p className="entry-description font-normal greyscale-2 p3-text">{`Enter any note — from meal ideas and ingredients to tips and hacks. Be sure to add any items you saved here to your Shopping List.`}</p>
            <form onSubmit={this.recipeSubmit} className="recipe-entry-form">
              <input type="text" className="text-input recipe-name" value={this.state.title} onChange={this.changeTitle} id="manual-entry-name" maxLength={5000}/>
              <label htmlFor="manual-entry-name" className="manual-entry-label micro-caps font-bold">{'Recipe Name'}</label>
              <input type="text" className="text-input recipe-description" value={this.state.note} onChange={this.changeNote} id="manual-entry-description" maxLength={5000}/>
              <label htmlFor="manual-entry-description" className="manual-entry-label micro-caps font-bold">{'Notes'}</label>
              <input type="submit" value="Save" className="submit-button btn-primary" disabled={disableSaveTitle} />
            </form>
          </React.Fragment>
        }
      </div>
    );
  }
}

MealPlannerManualEntryModal.propTypes = {
  toggleManualEntryModal: YummlyPropTypes.action,
  meal: YummlyPropTypes.meal,
  scheduleSelectedRecipes: YummlyPropTypes.action,
  updateSelectedRecipesToSchedule: YummlyPropTypes.action,
  scrapeEntryUrl: YummlyPropTypes.action,
  previewCardRecipe: YummlyPropTypes.recipe,
  addMeals: YummlyPropTypes.action,
  previewCardError: YummlyPropTypes.string,
  previewCardFetching: YummlyPropTypes.bool,
  updateMealPlanItem: YummlyPropTypes.action,
  clearPreviewCard: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    meal: state.app.showModal?.modalProps?.meal,
    previewCardRecipe: state.mealPlanning.previewCard.recipe,
    previewCardError: state.mealPlanning.previewCard.error,
    previewCardFetching: state.mealPlanning.previewCard.fetching,
  };
};

const mapDispatchToProps = {
  toggleManualEntryModal,
  updateSelectedRecipesToSchedule,
  scheduleSelectedRecipes,
  scrapeEntryUrl,
  addMeals,
  updateMealPlanItem,
  clearPreviewCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerManualEntryModal);
