import React from 'react';

import { closeModal } from 'actions/MealPlanning';
import { connect } from 'react-redux';
import Button from 'components/Pasta/Button';
import { pstIcons } from 'constants/FontFaceCharacters';
import Icon from 'components/Pasta/Icon';

const MealPlanModal = ({ title, subheading, onClickClose, children, closeModal, onBackClick }) => {

  function onClickedClose(e) {
    if (onClickClose) {
      onClickClose(e);
    }
    closeModal();
  }

  return (
    <div className="meal-plan-modal">
      <div className="modal-wrapper">
        <div className="modal-header">
          {onBackClick && <div className="nav-back-btn" onClick={onBackClick}>
            <Icon iconCode={pstIcons.F0003_005__Icon_carat_left}/>
            <span className="pst-p-small-strong">{`BACK`}</span>
          </div>
          }
          <div className="modal-title h4-text font-bold primary-dark">{title}</div>
          <div className="modal-subheading pst-p-small-regular">{subheading}</div>
        </div>
        {children}
        <div className="close-x-modal">
          <Button
            iconCode={pstIcons.F0003_00C__Icon_close}
            contained={true}
            onClick={onClickedClose}
          />
        </div>
      </div>
    </div>
  );
};

MealPlanModal.propTypes = {
  title: YummlyPropTypes.string,
  subheading: YummlyPropTypes.string,
  onClickClose: YummlyPropTypes.func,
  closeModal: YummlyPropTypes.action,
  children: YummlyPropTypes.element,
  onBackClick: YummlyPropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanModal);

