import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { toggleOnboardingMealPlanUpsellModal } from 'actions/Onboarding';
import Link from 'components/Link';
import { MEAL_PLANNING_ROUTE } from 'constants/routes';
import classNames from 'util/classNames';
import { VARIANT_1, VARIANT_2 } from 'constants/Experiments';
import cdn from 'util/cdn';
import Image from 'components/Image';
import Icon from 'components/Pasta/Icon';
import { logEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import { TRACKING, DDE } from 'constants/Tracking';
import { withRouter } from 'react-router-dom';

function OnboardingMealPlanUpsellModal(props) {
  const buttonText = `Check It Out`;
  const TRACKING_ID = 'plan_and_shop_upsell_onboarding';

  function exitClick() {
    props.logEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': 'external_search_upsell',
      'Prompt Name': TRACKING_ID,
      'Prompt Action': 'Exit',
    });

    props.toggleOnboardingMealPlanUpsellModal(false);
  }

  function ctaClick(e) {
    e.preventDefault();

    props.logEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': 'external_search_upsell',
      'Prompt Name': TRACKING_ID,
      'Prompt Action': 'CTA click',
      'Prompt Text': buttonText,
    });

    props.logDDEEvent(DDE.CTA_CLICK, {
      action_type: TRACKING_ID,
      button_text: buttonText,
      floating: false,
      pop_up: true,
    });

    props.toggleOnboardingMealPlanUpsellModal(false);

    props.history.push(MEAL_PLANNING_ROUTE);
  }

  useEffect(() => {
    props.logEvent(TRACKING.PROMPT_VIEW, {
      'Prompt Type': 'external_search_upsell',
      'Prompt Name': TRACKING_ID,
      'Prompt Action': 'CTA click',
      'Prompt Text': buttonText,
    });

    props.logEvent(TRACKING.PROMPT_VIEW, {
      'Prompt Type': 'external_search_upsell',
      'Prompt Name': TRACKING_ID,
      'Prompt Action': 'Exit',
    });

    props.logDDEEvent(DDE.CTA_IMPRESSION, {
      action_type: TRACKING_ID,
      button_text: buttonText,
      floating: false,
      pop_up: true,
    });
  }, []);

  const modalClasses = classNames({
    'onboarding-meal-upsell-modal': true,
    [props.variant]: true,
  });

  let modalDescription;
  let modalImage;
  let srcSet;
  const style = {};

  if (props.variant === VARIANT_1) {
    modalDescription = (
      <div className="p1-text font-normal greyscale-1 modal-description">{`Simplify dinnertime with Yummly’s smart shopping list and meal planner.`}</div>
    );

    modalImage = `${cdn.imagePrefix()}/onboardingUpsell/mealPlanUpsell-screenshot.png`;
    srcSet = `${cdn.imagePrefix()}/onboardingUpsell/mealPlanUpsell-screenshot@2x.png`;
    style.backgroundImage = `url(${cdn.imagePrefix()}/banner-marble-bkg.jpg)`;
  } else if (props.variant === VARIANT_2) {
    modalDescription = (
      <div className="modal-description-wrapper">
        <div className="p2-text font-normal greyscale-1 modal-description">{`Simplify dinnertime with Yummly’s smart shopping list and meal planner.`}</div>
        <ul className="description-points">
          <li className="description-point menu">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
              <g fill="none" fillRule="evenodd">
                <g stroke="#3B9792" strokeWidth="2">
                  <g transform="translate(-283 -211) translate(284 212)">
                    <rect width="24" height="30" x="7" rx="3" />
                    <rect width="26" height="12" y="5" fill="#FFF" rx="2" />
                    <rect width="4" height="4" x="5" y="9" rx="1" />
                    <path strokeLinecap="round" d="M13 9L21 9M13 13L21 13" />
                    <rect width="4" height="4" x="11" y="21" rx="1" />
                    <path strokeLinecap="round" d="M19 21L27 21M19 25L27 25" />
                  </g>
                </g>
              </g>
            </svg>
            <p className="p2-text font-normal greyscale-1 point-text">{`Get personalized meal plan recommendations`}</p>
          </li>
          <li className="description-point carrot">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
              <g fill="none" fillRule="evenodd">
                <g stroke="#3B9792" strokeWidth="2">
                  <g>
                    <g>
                      <path d="M7.41 8h4.332c3.314 0 6 2.686 6 6 0 .379-.036.757-.107 1.128l-4.843 25.293c-.312 1.627-1.884 2.694-3.511 2.382-1.185-.227-2.119-1.141-2.37-2.322L1.542 15.25c-.69-3.242 1.38-6.428 4.62-7.118.41-.087.83-.131 1.249-.131z" transform="translate(-279 -267) translate(280 268) scale(-1 1) rotate(45 -7.293 -16.456)" />
                      <path strokeLinecap="round" d="M3.015 18.5L8.015 18.5M4.136 23.793L7.722 23.793M10 0L10 8" transform="translate(-279 -267) translate(280 268) scale(-1 1) rotate(45 -7.293 -16.456)" />
                      <path strokeLinecap="round" d="M7 0L7 8" transform="translate(-279 -267) translate(280 268) scale(-1 1) rotate(45 -7.293 -16.456) rotate(-45 7 4)" />
                      <path strokeLinecap="round" d="M13 0L13 8" transform="translate(-279 -267) translate(280 268) scale(-1 1) rotate(45 -7.293 -16.456) scale(-1 1) rotate(-45 0 35.385)" />
                    </g>
                    <path fill="#FFF" d="M19.76 26.27l3.653 6.782c.523.972.16 2.185-.813 2.709-.292.157-.617.239-.948.239H20h0v2.928c0 1.105-.895 2-2 2s-2-.895-2-2V36h0-1.652c-1.104 0-2-.895-2-2 0-.331.083-.657.24-.948l3.651-6.782c.524-.972 1.737-1.336 2.71-.812.344.185.626.468.812.812z" transform="translate(-279 -267) translate(280 268) scale(-1 1) rotate(-45 0 75.42)" />
                  </g>
                </g>
              </g>
            </svg>
            <p className="p2-text font-normal greyscale-1 point-text">{`Add ingredients for planned meals with a click`}</p>
          </li>
          <li className="description-point cart">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="34" viewBox="0 0 41 34">
              <g fill="none" fillRule="evenodd">
                <g stroke="#3B9792" strokeWidth="2">
                  <g transform="translate(-279 -337) translate(280 338)">
                    <path d="M28.952 8.644h0-.07c-1.045.006-1.888-.836-1.882-1.882l.017-2.84c.006-1.046.859-1.899 1.905-1.905L31.762 2c1.046-.006 1.888.837 1.882 1.882v.105h0" transform="rotate(45 30.322 5.322)" />
                    <path d="M16.393 7h20.214c1.105 0 2 .895 2 2 0 .119-.01.238-.032.355l-2.163 12c-.172.952-1 1.645-1.968 1.645H18.556c-.967 0-1.796-.693-1.968-1.645l-2.163-12c-.196-1.087.526-2.127 1.613-2.323.117-.021.236-.032.355-.032z" />
                    <path strokeLinecap="round" d="M7 2h4.103c.982 0 1.821.622 1.992 1.474.649 2.64 1.074 4.461 1.275 5.467" />
                    <circle cx="20" cy="26" r="2" />
                    <circle cx="33" cy="26" r="2" />
                    <path strokeLinecap="round" d="M7.5 22.5L12.5 22.5M6.5 26L11.5 26" />
                    <path d="M3.911 5.644l2.647-.013c1.105-.006 2.005.885 2.01 1.99v.02l-.013 2.608c-.006 1.096-.893 1.984-1.99 1.99l-2.647.013c-1.104.005-2.004-.885-2.01-1.99v-.02l.013-2.608c.006-1.097.894-1.984 1.99-1.99z" transform="rotate(30 5.238 8.941)" />
                    <path strokeLinecap="round" d="M1 31.5L39 31.5" />
                  </g>
                </g>
              </g>
            </svg>
            <p className="p2-text font-normal greyscale-1 point-text">{`Take your list on the go or order groceries for delivery`}</p>
          </li>
        </ul>
      </div>
    );

    modalImage = `${cdn.imagePrefix()}/onboardingUpsell/mealPlanUpsell-tablet.jpg`;
    srcSet = `${cdn.imagePrefix()}/onboardingUpsell/mealPlanUpsell-tablet@2x.jpg`;
  }

  return (
    <div className={modalClasses} style={style}>
      <div className="cta-content">
        <Image
          src={`${cdn.imagePrefix()}/yummly.svg`}
          className="logo-image"
        />
        <div className="h0-text font-bold primary-dark modal-title">{`Plan. Shop. Relax.`}</div>
        {modalDescription}
        <Link href={MEAL_PLANNING_ROUTE} onClick={ctaClick} className="btn-primary modal-cta">{buttonText}</Link>
      </div>
      <div className="modal-image-wrapper">
        <Image src={modalImage} srcSet={srcSet} className="modal-image" />
      </div>
      <Icon iconName="xLarge" className="modal-closer primary-teal" onClick={exitClick} />
    </div>
  );
}

OnboardingMealPlanUpsellModal.propTypes = {
  toggleOnboardingMealPlanUpsellModal: YummlyPropTypes.action,
  variant: YummlyPropTypes.experimentVariant,
  logEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
};

const mapDispatchToProps = {
  toggleOnboardingMealPlanUpsellModal,
  logEvent,
  logDDEEvent,
};

export default connect(null, mapDispatchToProps)(withRouter(OnboardingMealPlanUpsellModal));
