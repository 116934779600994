import React from 'react';
import Image from 'components/Image';
import cdn from 'util/cdn';

const CollectionEmptyState = () => {
  return (
    <div className="collection-empty-state flex column justify-content-center">
      <Image className="empty-img" src={`${cdn.imagePrefix()}/mealplanner/empty-collection-hq.png`} size={64}/>
      <span className="pst-h7 title" >{`Add to this collection`}</span>
      <span className="pst-p-small-regular subtitle">{`You have yet to add any recipes to this collection`}</span>
    </div>
  );
};

CollectionEmptyState.propTypes = {};

export default CollectionEmptyState;
