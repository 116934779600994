import MakeModeTimerOverlay from 'bundles/modals/components/MakeModeTimerOverlay';
import MealPlanClearModal from 'bundles/modals/components/MealPlanClearModal';
import MealPlannerManualEntryModal from 'bundles/modals/components/MealPlannerManualEntryModal';
import MealPlannerPromo from 'bundles/modals/components/MealPlannerPromo';
import MealPlanRemoveModal from 'bundles/modals/components/MealPlanRemoveModal';
import MealPlannerScheduleRecipeModal from 'bundles/modals/components/MealPlannerScheduleRecipeModal';
import NewLogin from 'components/NewLogin';
import Picker from 'bundles/modals/components/Picker';
import ProGenericModal from 'bundles/modals/components/ProGenericModal';
import ProInternationalModal from 'bundles/modals/components/ProInternationalModal';
import ProSubscriptionModal from 'bundles/modals/components/ProSubscriptionModal';
import ShoppingListCategorySelectModal from 'bundles/modals/components/ShoppingListCategorySelectModal';
import ShoppingListClearModal from 'bundles/modals/components/ShoppingListClearModal';
import ShoppingListPromo from 'bundles/modals/components/ShoppingListPromo';
import VirtualPantryPromo from 'bundles/modals/components/VirtualPantryPromo';
import TooManyRecipesAlert from 'bundles/modals/components/TooManyRecipesAlert';
import OnboardingMealPlanUpsellModal from 'bundles/modals/components/OnboardingMealPlanUpsellModal';
import PaywallModal from 'bundles/modals/components/PaywallModal';
import LegalModal from 'bundles/modals/components/LegalModal';
import {
  MAKE_MODE_TIMER_KEY,
  MANUAL_ENTRY_MODAL_KEY,
  MEAL_PLAN_CLEAR_MODAL_KEY,
  MEAL_PLANNER_PROMO_KEY,
  MEAL_PLANNER_SCHEDULE_RECIPE_KEY,
  NEW_LOGIN_KEY,
  PRO_GENERIC_MODAL_KEY,
  PRO_INTERNATIONAL_MODAL_KEY,
  PRO_SUBSCRIPTION_MODAL_KEY,
  SHOPPING_LIST_CATEGORY_SELECT_KEY,
  SHOPPING_LIST_CLEAR_LIST_KEY,
  SHOPPING_LIST_MOBILE_PICKER_KEY,
  SHOPPING_LIST_PROMO_KEY,
  VIRTUAL_PANTRY_PROMO_KEY,
  MEAL_PLAN_REMOVE_KEY,
  TOO_MANY_RECIPES_ALERT_KEY,
  ONBOARDING_MEAL_PLAN_UPSELL_KEY,
  PAYWALL_MODAL_KEY,
  LEGAL_MODAL_KEY,
  MEAL_PLANNER_COOK_TIME_KEY,
  MEAL_PLANNER_DISCOVERY_KEY,
  MEAL_PLANNER_EDIT_SCHEDULE_KEY,
} from 'constants/BigModalKeys';
import MealPlannerCookTimeModal from './components/MealPlannerCookTimeModal/MealPlannerCookTimeModal';
import MealPlannerDiscoveryModal from 'bundles/modals/components/MealPlannerDiscoveryModal';
import MealPlannerEditScheduleModal from './components/MealPlannerEditScheduleModal/MealPlannerEditScheduleModal';

const MODAL_KEY_MAPPING = {
  [MAKE_MODE_TIMER_KEY]: MakeModeTimerOverlay,
  [MANUAL_ENTRY_MODAL_KEY]: MealPlannerManualEntryModal,
  [MEAL_PLAN_CLEAR_MODAL_KEY]: MealPlanClearModal,
  [MEAL_PLANNER_PROMO_KEY]: MealPlannerPromo,
  [NEW_LOGIN_KEY]: NewLogin,
  [PRO_GENERIC_MODAL_KEY]: ProGenericModal,
  [PRO_INTERNATIONAL_MODAL_KEY]: ProInternationalModal,
  [PRO_SUBSCRIPTION_MODAL_KEY]: ProSubscriptionModal,
  [SHOPPING_LIST_CATEGORY_SELECT_KEY]: ShoppingListCategorySelectModal,
  [SHOPPING_LIST_CLEAR_LIST_KEY]: ShoppingListClearModal,
  [SHOPPING_LIST_MOBILE_PICKER_KEY]: Picker,
  [SHOPPING_LIST_PROMO_KEY]: ShoppingListPromo,
  [VIRTUAL_PANTRY_PROMO_KEY]: VirtualPantryPromo,
  [MEAL_PLAN_REMOVE_KEY]: MealPlanRemoveModal,
  [MEAL_PLANNER_SCHEDULE_RECIPE_KEY]: MealPlannerScheduleRecipeModal,
  [MEAL_PLANNER_COOK_TIME_KEY]: MealPlannerCookTimeModal,
  [MEAL_PLANNER_EDIT_SCHEDULE_KEY]: MealPlannerEditScheduleModal,
  [MEAL_PLANNER_DISCOVERY_KEY]: MealPlannerDiscoveryModal,
  [TOO_MANY_RECIPES_ALERT_KEY]: TooManyRecipesAlert,
  [ONBOARDING_MEAL_PLAN_UPSELL_KEY]: OnboardingMealPlanUpsellModal,
  [PAYWALL_MODAL_KEY]: PaywallModal,
  [LEGAL_MODAL_KEY]: LegalModal,
};

export default MODAL_KEY_MAPPING;