import { getStringFunction } from 'util/localStrings';
import { DEFAULT } from 'constants/Experiments';

export const getCopy = getStringFunction(
  [
    {
      id: `shoppingList.title.${DEFAULT}`,
      defaultMessage: "Shopping List",
    },
    {
      id: `shoppingList.description.${DEFAULT}`,
      defaultMessage: "This feature works even better on our app. Download now!",
    },
    {
      id: `shoppingList.benefits.one.${DEFAULT}`,
      defaultMessage: "Add ingredients from any recipe with a click",
    },
    {
      id: `shoppingList.benefits.two.${DEFAULT}`,
      defaultMessage: "Auto-sync across devices so your list is always handy",
    },
    {
      id: `shoppingList.benefits.three.${DEFAULT}`,
      defaultMessage: "Get groceries delivered from local stores",
    },
  ],
  'shoppingList'
);