import React, { Component } from 'react';

import { bindInstance } from 'util/dev';
import { guidedTrackEvent } from 'actions/Tracking';
import { deepProperty } from 'util/object';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setTimesupNotificationAndDisableCarouselNav } from 'actions/MakeMode';
import { toggleMakeModeTimerOverlay } from 'actions/App';

// used when the timer ends and you're not on that current step in Make Mode
class MakeModeTimerOverlay extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  handleClick() {
    const { guidedTrackEvent, location, setTimesupNotificationAndDisableCarouselNav, makeMode } = this.props;
    const query = new URLSearchParams(location.search);

    const currentStep = query.get('step');

    guidedTrackEvent('Make Mode Dismiss Timer', {
      'Step Number': currentStep,
    });

    setTimesupNotificationAndDisableCarouselNav(true);
    this.props.toggleMakeModeTimerOverlay(false);
    this.props.history.push(`${location.pathname}?makeMode=true&step=${deepProperty(makeMode, 'currentTimer.step')}`);
  }
  render() {
    return (
      <div className="timesup-notification" onClick={this.handleClick}>
        <div className="timesup-blur"/>
        <div className="timesup-block">
          <span className="font-bold timesup-message">{this.props.messageOverride || 'Timer is done! Please check and proceed to the next step.'}</span>
          <button className="button btn-primary timesup-btn">{'OK'}</button>
        </div>
      </div>
    );
  }
}

MakeModeTimerOverlay.propTypes = {
  guidedTrackEvent: YummlyPropTypes.action,
  location: YummlyPropTypes.location,
  setTimesupNotificationAndDisableCarouselNav: YummlyPropTypes.action,
  makeMode: YummlyPropTypes.makeMode,
  messageOverride: YummlyPropTypes.string,
  toggleMakeModeTimerOverlay: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
};

const mapStateToProps = (state) => {
  return {
    makeMode: state.makeMode,
  };
};

const mapDispatchToProps = {
  guidedTrackEvent,
  setTimesupNotificationAndDisableCarouselNav,
  toggleMakeModeTimerOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MakeModeTimerOverlay));
