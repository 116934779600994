// E0001 • Chip
import React from 'react';

import classNames from 'util/classNames';


const Chip = ({ label, icon, bgColor, labelColor, iconColor, tint, onClick, chipClass }) => {
  const chipClasses = classNames({
    'pst-chip': true,
    'flex': true,
    'align-items-center': true,
    'bg-dark': bgColor === 'dark',
    'bg-primary': bgColor === 'primary',
    'has-icon': Boolean(icon),
    tint,
    icon,
    [chipClass]: chipClass,
  });

  const labelStyle = {};
  const iconStyle = {};
  const rootStyle = {};

  if (bgColor) {
    rootStyle['backgroundColor'] = bgColor;
  }

  if (labelColor) {
    labelStyle['color'] = labelColor;
  }
  if (iconColor) {
    iconStyle['color'] = iconColor;
  }

  return (
    <div className={chipClasses} style={rootStyle} onClick={onClick}>
      {icon && <span className="pst-icon" data-icon={icon} style={iconStyle}/>}
      <span className="pst-ctrl-micro-strong-caps flex" style={labelStyle}>
        {label}
      </span>
    </div>
  );
};

Chip.propTypes = {
  label: YummlyPropTypes.string,
  bgColor: YummlyPropTypes.string,
  labelColor: YummlyPropTypes.string,
  iconColor: YummlyPropTypes.string,
  icon: YummlyPropTypes.string,
  tint: YummlyPropTypes.bool,
  onClick: YummlyPropTypes.func,
};


export default Chip;
