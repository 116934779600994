import React, { Component } from 'react';
import { connect } from 'react-redux';

import { icons } from 'constants/FontFaceCharacters';
import cdn from 'util/cdn';
import Image from 'components/Image';
import AppStoreLinks from 'components/AppStoreLinks';
import { createAppDownloadFloodlightTag } from 'util/floodlight';
import { bindInstance } from 'util/dev';
import { toggleShoppingListPromoModal } from 'actions/App';
import { logEvent } from 'actions/mixpanel';
import { getCopy } from './ShoppingListPromoCopy';
import { DEFAULT } from 'constants/Experiments';
import cookie from 'util/cookies';
import { TRACKING } from 'constants/Tracking';
import classNames from 'util/classNames';
import { isAndroid, isIOS } from 'util/userAgent';
import { SL_PROMO_CLOSED } from 'constants/Cookies';

const benefits = [
  getCopy(`benefits.one.${DEFAULT}`),
  getCopy(`benefits.two.${DEFAULT}`),
  getCopy(`benefits.three.${DEFAULT}`),
];

export class ShoppingListPromo extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this);
    this.cls = classNames({
      'shoppingListPromo': true,
      'browser-android': isAndroid(),
      'browser-ios': isIOS(),
    }).split(" ");
  }

  componentDidMount() {
    this.addAppPromoClassToBody();
    this.props.track(TRACKING.PROMPT_VIEW, {
      'Prompt Type': TRACKING.SHOPPING_LIST_DOWNLOAD_PROMO,
    });
  }

  componentWillUnmount() {
    this.removeAppPromoClassFromBody();
  }

  onDismissClick() {
    const { toggleShoppingListPromoModal } = this.props;

    if (toggleShoppingListPromoModal) {
      toggleShoppingListPromoModal(false);
      cookie.set(SL_PROMO_CLOSED, new Date().getTime());
      this.props.track(TRACKING.PROMPT_CLICK, {
        'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
        'Prompt Type': TRACKING.SHOPPING_LIST_DOWNLOAD_PROMO,
      });
    }
  }

  onDownloadClick() {
    const { dismissHandler } = this.props;

    if (dismissHandler) {
      dismissHandler();
    }

    // Create floodlight tag when clicking download button
    createAppDownloadFloodlightTag();
  }

  addAppPromoClassToBody() {
    if (__CLIENT__ && document.body.classList) {
      document.body.classList.add(...this.cls);
    }
  }

  removeAppPromoClassFromBody() {
    if (__CLIENT__ && document.body.classList) {
      document.body.classList.remove(...this.cls);
    }
  }
  render() {
    return (
      <div className="shopping-list-promo">
        <Image className="promo-main-image" src={`${cdn.imagePrefix()}/ShoppingList-promo.jpg`} />
        <span
          className="y-icon cancel"
          data-icon={icons.xSmall}
          onClick={this.onDismissClick}
        />
        <div className="shopping-list-content">
          <h4 className="font-bold gr-title h2-text primary-dark">{getCopy(`title.${DEFAULT}`)}</h4>
          <h5 className="font-regular gr-desc h5-text greyscale-2">{getCopy(`description.${DEFAULT}`)}</h5>
          {
            benefits.map((benefit, i) => {
              return (
                <div key={i} className="benefit-item h5-text">
                  <span className="y-icon checkmark primary-teal" data-icon={icons.checkSmall} />
                  <h3 className="benefit-description h5-text greyscale-1 font-bold">{benefit}</h3>
                </div>);
            })
          }
          <AppStoreLinks shouldCheckRelevantStore={true}/>
        </div>
      </div>
    );
  }
}

ShoppingListPromo.propTypes = {
  dismissHandler: YummlyPropTypes.func,
  toggleShoppingListPromoModal: YummlyPropTypes.action,
  track: YummlyPropTypes.action,
};
const mapDispatchToProps = {
  toggleShoppingListPromoModal,
  track: logEvent,
};

export default connect(null, mapDispatchToProps)(ShoppingListPromo);
