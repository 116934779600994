import React from 'react';

import { connect } from 'react-redux';
import { str } from 'constants/MealPlanning';
import { toggleEditScheduleModal,
  updateEditScheduleViewAppointmentDate,
  updateAppointmentDatesToSchedule,
  updateAppointmentDatesToUnschedule,
  updateSelectedAppointmentSchedule } from "actions/MealPlanning";
import StickyAction from 'components/Pasta/StickyAction';
import { selectedAppointmentSelector,
  selectedAppointmentDateSelector,
  appointmentsForEditScheduleViewDateRangeSelector,
  selectedAppointmentDateRangeSelector,
  editScheduleViewDateRangeSelector,
  editScheduleViewDateSelector,
  selectedAppointmentDatesToScheduleSelector,
  selectedAppointmentDatesToUnscheduleSelector } from "selectors/mealPlanning";
import MealPlanModal from 'components/MealPlanModal/MealPlanModal';
import { updateAppointment } from 'actions/MealPlanning';
import DateToolbar from 'components/Pasta/DateToolbar';
import moment from 'moment';
import MealPlanAppointmentCard from 'bundles/mealPlanning/components/MealPlanAppointmentCard/MealPlanAppointmentCard';
import ActionBar from 'components/Pasta/ActionBar';
import Switch, { SWITCH_TYPES } from 'components/Pasta/Switch';

const strings = {
  TITLE: 'Edit Schedule',
  SUBHEADING: 'Select which day(s) to schedule this recipe on',
};

const MealPlannerEditScheduleModal = ({
  selectedAppointment,
  editScheduleViewDateRange,
  editScheduleViewDate,
  weekAppointments,
  toggleEditScheduleModal,
  updateEditScheduleViewAppointmentDate,
  updateAppointmentDatesToSchedule,
  updateAppointmentDatesToUnschedule,
  selectedAppointmentDatesToSchedule,
  selectedAppointmentDatesToUnschedule,
  updateSelectedAppointmentSchedule,
}) => {

  function onClickDone() {
    updateSelectedAppointmentSchedule();
    toggleEditScheduleModal(false);
  }

  function moveToSelectedDate(date) {
    const selectedDate = moment(date);
    updateEditScheduleViewAppointmentDate(selectedDate);
  }

  function moveSelectedDate(direction) {
    const newEditScheduleViewDate = new Date(editScheduleViewDate);
    newEditScheduleViewDate.setDate((newEditScheduleViewDate.getDate() + 7 * direction));
    updateEditScheduleViewAppointmentDate(newEditScheduleViewDate);
  }

  function moveSelectedDateBackward() {
    moveSelectedDate(-1);
  }
  function moveSelectedDateForward() {
    moveSelectedDate(1);
  }

  function onClickClose() {
    updateAppointmentDatesToSchedule([]);
  }

  const formattedStartDate = moment(editScheduleViewDateRange?.start)?.format("MMM D");
  const formattedEndDate = moment(editScheduleViewDateRange?.end)?.format("MMM D");
  const dateText = formattedStartDate + " - " + formattedEndDate;

  return (
    <MealPlanModal
      title={strings.TITLE}
      subheading={strings.SUBHEADING}
      onClickClose={onClickClose}
    >
      <>
        <div className="meal-plan-edit-schedule-modal">
          <MealPlanAppointmentCard
            appointmentData={selectedAppointment}
            hideOptions={true}
            preventNav={true}
          />
          <DateToolbar
            dateText={dateText}
            onNavLeft={moveSelectedDateBackward}
            onNavRight={moveSelectedDateForward}
            onDateSelected={moveToSelectedDate}
          />
          {
            weekAppointments.map((day) => {
              const editAppointmentRecipeID = selectedAppointment['recipe-id'];

              const recipeIsScheduledForDay = day?.appointments?.find((appt) => {
                const appointmentRecipeID = appt['recipe-id'];
                return editAppointmentRecipeID === appointmentRecipeID;
              });

              const weekDate = day?.date_YYYY_MM_DD;

              const isInDatesToSchedule = selectedAppointmentDatesToSchedule?.find((apptDate) => weekDate === apptDate);
              const isInDatesToUnschedule = selectedAppointmentDatesToUnschedule?.find((apptDate) => weekDate === apptDate);

              function onClickedDate() {
                if (recipeIsScheduledForDay) {
                  if (!isInDatesToUnschedule) {
                    updateAppointmentDatesToUnschedule([...selectedAppointmentDatesToUnschedule, weekDate]);
                  } else {
                    updateAppointmentDatesToUnschedule([...selectedAppointmentDatesToUnschedule].filter((e) => e !== weekDate));
                  }
                  return;
                }

                if (!isInDatesToSchedule) {
                  updateAppointmentDatesToSchedule([...selectedAppointmentDatesToSchedule, weekDate]);
                } else {
                  if (recipeIsScheduledForDay) {
                    updateAppointmentDatesToUnschedule([...selectedAppointmentDatesToUnschedule, weekDate]);
                  } else {
                    updateAppointmentDatesToSchedule([...selectedAppointmentDatesToSchedule].filter((e) => e !== weekDate));
                  }
                }
              }

              return <ActionBar key={day.dayOfWeekFriendly} showBorder={true}>
                <Switch
                  label={day.dayOfWeekFriendly}
                  type={SWITCH_TYPES.PLUS}
                  onClick={onClickedDate}
                  checked={!((!recipeIsScheduledForDay && !isInDatesToSchedule) || isInDatesToUnschedule)}
                />
              </ActionBar>;
            })
          }
        </div>
        <StickyAction
          actionLabel={str('done')}
          onClick={onClickDone}
        />
      </>
    </MealPlanModal>
  );
};

MealPlannerEditScheduleModal.propTypes = {
  updateSelectedAppointmentSchedule: YummlyPropTypes.action,
  toggleEditScheduleModal: YummlyPropTypes.action,
  updateAppointmentDatesToSchedule: YummlyPropTypes.action,
  updateAppointmentDatesToUnschedule: YummlyPropTypes.action,
  updateEditScheduleViewAppointmentDate: YummlyPropTypes.action,
  selectedAppointment: YummlyPropTypes.objectOrString,
  weekAppointments: YummlyPropTypes.arrayOfObjects,
  editScheduleViewDateRange: YummlyPropTypes.objectOrString,
  editScheduleViewDate: YummlyPropTypes.objectOrString,
  selectedAppointmentDatesToSchedule: YummlyPropTypes.stringArray,
  selectedAppointmentDatesToUnschedule: YummlyPropTypes.stringArray,
};

const mapStateToProps = (state) => {
  return {
    selectedAppointment: selectedAppointmentSelector(state),
    selectedAppointmentDate: selectedAppointmentDateSelector(state),
    weekAppointments: appointmentsForEditScheduleViewDateRangeSelector(state),
    selectedAppointmentDateRange: selectedAppointmentDateRangeSelector(state),
    editScheduleViewDateRange: editScheduleViewDateRangeSelector(state),
    editScheduleViewDate: editScheduleViewDateSelector(state),
    selectedAppointmentDatesToSchedule: selectedAppointmentDatesToScheduleSelector(state),
    selectedAppointmentDatesToUnschedule: selectedAppointmentDatesToUnscheduleSelector(state),
  };
};

const mapDispatchToProps = {
  updateAppointment,
  updateAppointmentDatesToSchedule,
  updateAppointmentDatesToUnschedule,
  updateSelectedAppointmentSchedule,
  toggleEditScheduleModal,
  updateEditScheduleViewAppointmentDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerEditScheduleModal);
