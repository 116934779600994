// P0025 • Meal Planner Card
import React from 'react';

import Chip from 'components/Pasta/Chip';

const MealPlannerCard = ({ title, bodyText, imgURL, chipsConfigs, actionButtons, onClick }) => {
  return <div className="pst-card flex" onClick={onClick}>
    {imgURL &&
      <img
        src={imgURL}
        className="pst-card-img"
      />
    }
    <div className="pst-card-content flex">
      <div className="pst-h8 title-text">{title}</div>
      {bodyText && <div className="pst-p-regular pst-gray-500">{bodyText}</div>}
      <div className="flex space-between full-width">
        <div className="chips flex space-between align-items-center">
          <div className="chips-container">
            {chipsConfigs?.map((config) => {
              return <Chip
                chipClass={config.chipClass}
                key={config.label}
                label={config.label}
                primary={config.primary}
                bgColor={config.bgColor}
                icon={config.icon}/>;
            })}
          </div>
        </div>
        <div className="actions flex space-between align-items-center">
          {actionButtons || ''}
        </div>
      </div>
    </div>
  </div>;
};

MealPlannerCard.propTypes = {
  title: YummlyPropTypes.string,
  bodyText: YummlyPropTypes.string,
  imgURL: YummlyPropTypes.string,
  chipsConfigs: YummlyPropTypes.arrayOfObjects,
  actionButtons: YummlyPropTypes.element,
  onClick: YummlyPropTypes.func,
};

export default MealPlannerCard;
