import React, { useEffect } from 'react';

import Button from 'components/Button';
import Icon from 'components/Pasta/Icon';
import cookies from 'util/cookies';
import { deleteMeals, toggleRemoveModal } from 'actions/MealPlanning';
import { connect } from 'react-redux';
import { MP_REMOVE_MODAL_COOKIE } from 'constants/Cookies';
import { logEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import { DDE } from 'constants/Tracking';

const buttonText = 'Remove from Meal Planner';
function MealPlanRemoveModal(props) {

  useEffect(() => {
    props.logEvent('Prompt View', {
      'Prompt Name': 'Remove After Made It',
    });

    props.logDDEEvent(DDE.CTA_IMPRESSION, {
      action_type: 'meal_plan_remove',
      button_text: buttonText,
      floating: false,
      pop_up: true,
    });
  }, []);

  function deleteMeals(e) {
    e.preventDefault();
    props.deleteMeals({
      meals: [props.meal],
      trackingProps: {
        mpRemoveType: 'Meal Plan Page',
        ddeRemoveType: 'meal_plan_list_explicit',
      },
    });

    props.toggleRemoveModal(false);
  }

  function closeModal() {
    cookies.set(MP_REMOVE_MODAL_COOKIE, true);

    props.toggleRemoveModal(false);
  }

  return (
    <div className="meal-plan-remove-modal">
      <div className="remove-modal background-light">
        <div className="remove-modal-wrapper">
          <div className="modal-title h4-text font-bold primary-dark">{'Remove this recipe from your meal plan?'}</div>
          <div className="flex-expander" />
          <div className="action-tray">
            <Button className="clear-meals btn-primary" onClick={deleteMeals}>{buttonText}</Button>
            <Button className="close-modal btn-secondary" onClick={closeModal}>{'Not Now'}</Button>
          </div>
          <Icon className="close-x-modal greyscale-3 cursor-pointer" iconName="xLarge" onClick={closeModal} />
        </div>
      </div>
    </div>
  );
}

MealPlanRemoveModal.propTypes = {
  deleteMeals: YummlyPropTypes.action,
  meal: YummlyPropTypes.meal,
  toggleRemoveModal: YummlyPropTypes.action,
  logEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  const meal = state.app.showModal?.modalProps?.meal;

  return {
    meal,
  };
};

const mapDispatchToProps = {
  deleteMeals,
  toggleRemoveModal,
  logEvent,
  logDDEEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanRemoveModal);
