import { getStringFunction } from 'util/localStrings';
import { DEFAULT } from 'constants/Experiments';

export const str = getStringFunction(
  [
    {
      id: `proGenericModal.subscriptionTitle.${DEFAULT}`,
      defaultMessage: `You're subscribed`,
    },
    {
      id: `proGenericModal.subscriptionSubtitle.${DEFAULT}`,
      defaultMessage: `Congratulations — great things are about to happen in your kitchen!`,
    },
    {
      id: `proGenericModal.updateTitle.${DEFAULT}`,
      defaultMessage: 'Success',
    },
    {
      id: `proGenericModal.updateSubtitle.${DEFAULT}`,
      defaultMessage: 'Your payment method has been updated.',
    },
    {
      id: `proGenericModal.deletePaymentTitle.${DEFAULT}`,
      defaultMessage: 'Success',
    },
    {
      id: `proGenericModal.deletePaymentSubtitle.${DEFAULT}`,
      defaultMessage: 'Your payment method has been removed.',
    },
    {
      id: `proGenericModal.paymentFailureTitle.${DEFAULT}`,
      // TODO: is this the right string here?
      defaultMessage: 'Payment Problem',
    },
    {
      id: `proGenericModal.cancelTitle.${DEFAULT}`,
      defaultMessage: "Are you sure you want to give up your smart tools?",
    },
    {
      id: `proGenericModal.cancelSubtitle.${DEFAULT}`,
      defaultMessage: 'Your Yummly subscription gives you access to meal planning features, recipe scheduling, detailed nutrition information, advanced search filters, exclusive recipes, and more. Stay subscribed to keep your access!',
    },
    {
      id: `proGenericModal.paymentFailSubtitle.${DEFAULT}`,
      defaultMessage: 'We were unable to process your payment. Please check your payment information and try again.',
    },
    {
      id: `proGenericModal.systemFailSubtitle.${DEFAULT}`,
      defaultMessage: 'We were unable to process your payment. Please try again later.',
    },
    {
      id: `proGenericModal.platformFailureTitle.${DEFAULT}`,
      // TODO: is this the right string here?
      defaultMessage: 'Platform Problem',
    },
    {
      id: `proGenericModal.platformFailureSubtitle.${DEFAULT}`,
      defaultMessage: `You have subscribed through %s. Please use your %s Device to manage your subscription.`,
    },
    {
      id: `proGenericModal.platformFailureMoreInfo.${DEFAULT}`,
      defaultMessage: 'For more information, visit our',
    },
    {
      id: `proGenericModal.platformFailFAQ.${DEFAULT}`,
      defaultMessage: 'FAQs',
    },
    {
      id: `proGenericModal.unableToRemoveAccountTitle.${DEFAULT}`,
      defaultMessage: 'Unable to delete your account',
    },
    {
      id: `proGenericModal.unableToRemoveAccountSubtitle.${DEFAULT}`,
      defaultMessage: 'In order to delete your account, you must cancel your Yummly subscription.',
    },
    {
      id: `proGenericModal.alreadyProMemberTitle.${DEFAULT}`,
      defaultMessage: 'Already a Pro Member',
    },
    {
      id: `proGenericModal.alreadyProMemberSubtitle.${DEFAULT}`,
      defaultMessage: 'You already have an active Pro subscription.',
    },
    {
      id: `proGenericModal.startExplore.${DEFAULT}`,
      defaultMessage: 'Get Started',
    },
    {
      id: `proGenericModal.okay.${DEFAULT}`,
      defaultMessage: 'Done',
    },
    {
      id: `proGenericModal.notNow.${DEFAULT}`,
      defaultMessage: 'Stay Subscribed',
    },
    {
      id: `proGenericModal.confirm.${DEFAULT}`,
      defaultMessage: 'Cancel Subscription',
    },
    {
      id: 'proGenericModal.cancelSurvey.title',
      defaultMessage: 'Yummly subscription successfully canceled.',
    },
    {
      id: 'proGenericModal.cancelSurvey.subtitle',
      defaultMessage: 'Thanks for cooking with us! If you have a moment, we’d love to hear why you decided to unsubscribe:',
    },
    {
      id: 'proGenericModal.cardDeclined.title',
      defaultMessage: 'Your card was declined.',
    },
    {
      id: 'proGenericModal.cardDeclined.subtitle',
      defaultMessage: 'Please check your payment information or try again with a different card.',
    },
    {
      id: 'proGenericModal.cardExpired.title',
      defaultMessage: 'Expiration date cannot be in the past.',
    },
    {
      id: 'proGenericModal.cardExpired.subtitle',
      defaultMessage: 'Please check your entry or try again with a different card.',
    },
    {
      id: 'proGenericModal.incorrectCVC.title',
      defaultMessage: 'Security code is not valid.',
    },
    {
      id: 'proGenericModal.incorrectCVC.subtitle',
      defaultMessage: 'Please check your entry or try again with a different card.',
    },
    {
      id: 'proGenericModal.incorrectNumber.title',
      defaultMessage: 'Your card number is incorrect.',
    },
    {
      id: 'proGenericModal.incorrectNumber.subtitle',
      defaultMessage: 'Please check your entry or try again with a different card.',
    },
  ],
  'proGenericModal',
);
