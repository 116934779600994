import React, { Component } from 'react';

import { bindInstance } from 'util/dev';
import { connect } from 'react-redux';
import Image from 'components/Image';
import PaymentForm from 'components/PaymentForm';
import Subscription from 'components/Subscription';
import StripeWrapper from 'components/StripeWrapper';
import cdn from 'util/cdn';
import { icons } from 'constants/FontFaceCharacters';
import { toggleProSubscriptionModal } from 'actions/App';
import { TRACKING, DDE } from 'constants/Tracking';
import { logEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import { SETTINGS_PAGE } from 'util/location';

class ProSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  componentDidMount() {
    const { subscription, logMixpanelEvent, logDDEEvent } = this.props;
    if (subscription.state) {
      logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.YUMMLY_PRO_MANAGE_SUBSCRIPTION,
      });

      logDDEEvent(DDE.CTA_IMPRESSION, {
        "action_type": 'cancel_subscription',
        "module_id": 'Subscription Status',
        "button_text": "Cancel Subscription",
        "floating": false,
        "pop_up": true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageLocations[SETTINGS_PAGE] !== this.props.pageLocations[SETTINGS_PAGE]) {
      this.props.toggleProSubscriptionModal(false);
    }
  }

  closeModal() {
    this.props.toggleProSubscriptionModal(false);
  }

  render() {
    return (
      <div className="pro-subscription-modal background-light">
        <span className="y-icon closeX" data-icon={icons.xLarge} role="button" onClick={this.closeModal}/>
        <Image
          src={`${cdn.imagePrefix()}/yummly.svg`}
          alt={'Yummly'}
          className="logo-image"
        />
        <Subscription isSubscriptionModal={true} />
        {
          __CLIENT__ &&
          <StripeWrapper>
            <PaymentForm isSubscriptionModal={true}/>
          </StripeWrapper>
        }
      </div>
    );
  }
}

ProSubscriptionModal.propTypes = {
  toggleProSubscriptionModal: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
  subscription: YummlyPropTypes.subscription,
  pageLocations: YummlyPropTypes.pageLocations,
};

const mapStateToProps = state => {
  return {
    subscription: state.pro.subscription || {},
    pageLocations: state.pageLocations,
  };
};

const mapDispatchToProps = {
  toggleProSubscriptionModal,
  logMixpanelEvent: logEvent,
  logDDEEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProSubscriptionModal);
