import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindInstance } from 'util/dev';
import { deepProperty } from 'util/object';
import Button from 'components/Button';
import { icons } from 'constants/FontFaceCharacters';
import { toggleMobilePicker } from 'actions/App';
import { logTrackingEvents } from 'actions/Tracking';
import {
  QUANTITY_NUMBER_KEY,
  CATEGORY_KEY,
  CATEGORY_ID_KEY,
  QUANTITY_FRACTIONS,
  UNIT_KEY,
  constructShoppingListItem,
  UNIT_NONE,
  PICKER_UNIT_KEY,
  IMPERIAL,
  DISPLAY_VALUE,
} from 'util/shoppingList';
import { MODAL_TYPE_UPDATE_QUANTITY, ITEM_INCOMPLETE, ITEM_COMPLETED } from 'constants/index';
import {
  clearShoppingModal,
  getShoppingListScreenType,
  setEditItem,
  setUpdatedShoppingItem,
  syncShoppingList,
} from 'actions/ShoppingList';
import { showZendesk } from 'actions/App';
import { logEvent } from 'actions/mixpanel';
import { TRACKING } from 'constants/Tracking';
import { firstCap } from 'util/string';
import { ingredientCategoriesSelector } from 'selectors/metadata';
import classNames from 'util/classNames';

import {
  UPDATE_INGREDIENT_CATEGORY,
  UPDATE_INGREDIENT_QUANTITY,
} from 'components/TrayUpdatedNotification';

const CATEGORIES = {
  QUANTITY: 'Quantity',
  CATEGORY: 'Category',
};

export class Picker extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      initialState: {
        isQuant: props.pickerType === MODAL_TYPE_UPDATE_QUANTITY,
      },
      debounce: {
        scroll: 100,
      },
    });
    this.numberPicker = React.createRef();
    this.fractionPicker = React.createRef();
    this.unitPicker = React.createRef();
    this.categoryPicker = React.createRef();

    this.quantities = {
      quantity: '0',
      fraction: '0',
      unit: null,
    };
    this.categoryName = '';
    this.categoryId = '';
    this.initialScroll = false;
  }

  componentDidMount() {
    const item = this.props.item || {},
      quant = item[QUANTITY_NUMBER_KEY],
      unit = item[UNIT_KEY],
      categoryName = item[CATEGORY_KEY],
      categoryId = item[CATEGORY_ID_KEY];

    this.quantities.quantity = quant ? Number(`${parseInt(quant, 10)}`) : quant;
    this.quantities.fraction = this.findFractionString(quant % 1);
    if (!this.quantities.fraction) {
      this.quantities.fraction = quant % 1 > 0 ? '1/8' : '0';
    }
    this.quantities.unit = unit;

    this.setState({
      quantities: this.quantities,
      category: {
        categoryName,
        categoryId,
      },
    });

    this.props.showZendesk(false);
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.isQuant !== this.state.isQuant || !this.initialScroll) {
      this.defaultScroll();
      this.initialScroll = true;
    }
  }


  defaultScroll() {
    const activeNumberDOM = this.findActiveDOM(this.numberPicker),
      activeFractionDOM = this.findActiveDOM(this.fractionPicker),
      activeUnitDOM = this.findActiveDOM(this.unitPicker),
      activeCategoryDOM = this.findActiveDOM(this.categoryPicker);

    if (activeNumberDOM) {
      this.numberPicker.current.scrollTop = activeNumberDOM.offsetHeight * parseInt(activeNumberDOM.getAttribute('order'), 10);
    }

    if (activeFractionDOM) {
      this.fractionPicker.current.scrollTop = activeFractionDOM.offsetHeight * parseInt(activeFractionDOM.getAttribute('order'), 10);
    }

    if (activeUnitDOM) {
      this.unitPicker.current.scrollTop = activeUnitDOM.offsetHeight * parseInt(activeUnitDOM.getAttribute('order'), 10);
    }

    if (activeCategoryDOM) {
      this.categoryPicker.current.scrollTop = activeCategoryDOM.offsetHeight * parseInt(activeCategoryDOM.getAttribute('order'), 10);
    }
  }

  findActiveDOM(DOM) {
    if (!DOM.current) {
      return null;
    }
    const children = DOM.current.children;
    for (const item of children) {
      if (item.classList.value.split(' ').includes('active')) {
        return item;
      }
    }
    return null;
  }

  clickCategory(e) {
    e.preventDefault();
    this.setState({
      isQuant: e.target.innerText === CATEGORIES.QUANTITY,
    });
  }

  close() {
    this.props.showZendesk(true);
    this.props.toggleMobilePicker(false);
    this.props.clearShoppingModal();
  }

  save() {
    const {
      item,
      syncShoppingList,
      setEditItem,
      logTrackingEvents,
      mixpanelEvents,
      DDEEvents,
    } = this.props;

    const isQuantUnitChanged = this.quantities.quantity !== item[QUANTITY_NUMBER_KEY] || this.quantities.unit !== item[UNIT_KEY],
      isCategoryChanged = this.state.category.categoryName !== firstCap(decodeURIComponent(item[CATEGORY_KEY]));

    if (isQuantUnitChanged || isCategoryChanged) {
      const quantDecimal = Number(this.quantities.fraction.toString().split('/').reduce((p, c) => {
          return p / c;
        })),
        quantInteger = parseInt(this.quantities.quantity, 10),
        editedCategoryName = this.state.category.categoryName || item[CATEGORY_KEY],
        editedCategoryId = this.state.category.categoryId || item[CATEGORY_ID_KEY],
        editedUnit = this.quantities.unit || item[UNIT_KEY];

      const updatedItem = constructShoppingListItem({
        ...item,
        [QUANTITY_NUMBER_KEY]: Number((quantInteger + quantDecimal).toFixed(2)),
        [UNIT_KEY]: editedUnit,
        [CATEGORY_KEY]: firstCap(decodeURIComponent(editedCategoryName)),
        [CATEGORY_ID_KEY]: editedCategoryId,
      });

      syncShoppingList([updatedItem]);

      const mpProps = {},
        { shoppingList: { viewType } } = this.props,
        description = firstCap(updatedItem['description']);

      if (isQuantUnitChanged) {
        mpProps['Quantity Edited'] = editedUnit;

        this.props.setUpdatedShoppingItem(updatedItem, UPDATE_INGREDIENT_QUANTITY);
      } else if (isCategoryChanged) {
        mpProps['Category Edited'] = editedCategoryName;
        if (mixpanelEvents && mixpanelEvents[0]) {
          mixpanelEvents[0].properties['Category'] = this.state.category.categoryName;
        }
        logTrackingEvents({
          mixpanelEvents,
          DDEEvents,
        });

        this.props.setUpdatedShoppingItem(updatedItem, UPDATE_INGREDIENT_CATEGORY);
      }

      if (updatedItem.status === ITEM_INCOMPLETE) {
        mpProps['Item Unchecked'] = description;
      } else if (updatedItem.status === ITEM_COMPLETED) {
        mpProps['Item Checked'] = description;
      }

      // edit category
      this.props.logMixpanelEvent(TRACKING.SHOPPING_LIST_ITEM_ACTIONS, {
        'Item Name': description,
        'Shopping List Screen Type': getShoppingListScreenType(viewType),
        ...mpProps,
      });
    }

    setEditItem(null);
    this.close();
  }

  scroll(target) {
    const index = Math.round((target.scrollTop + 45) / target.scrollHeight * (target.childElementCount + 1));
    const text = deepProperty(target, `children[${index}].innerText`, '').toString();

    if (target === this.fractionPicker.current) {
      this.quantities.fraction = text;
    } else if (target === this.numberPicker.current) {
      this.quantities.quantity = text;
    } else if (target === this.unitPicker.current) {
      this.quantities.unit = text;
    } else if (target === this.categoryPicker.current) {
      const categoryElement = target.children[index];
      const categoryId = deepProperty(categoryElement, 'dataset.categoryId');
      this.categoryName = text;
      this.categoryId = categoryId;
    }

    target.scrollTop = target.children[index].offsetHeight * Math.round(target.scrollTop / target.children[index].offsetHeight);

    this.resetAllChildrenCssClass(target);

    target.children[index].classList.add('font-bold');

    this.setState({
      quantities: this.quantities,
      category: {
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      },
    });
  }

  resetAllChildrenCssClass(target) {
    for (let i = 1; i < target.children.length - 1; i++) { // skip head and tail placeholders
      target.children[i].className = 'h4-text';
    }
  }

  scrollNumber() {
    this.scroll(this.numberPicker.current);
  }

  scrollFraction() {
    this.scroll(this.fractionPicker.current);
  }

  scrollCategory() {
    this.scroll(this.categoryPicker.current);
  }

  scrollUnit() {
    this.scroll(this.unitPicker.current);
  }

  findFractionString(val) {
    const index = Object.values(QUANTITY_FRACTIONS).indexOf(Number(val.toFixed(2)));
    return Object.keys(QUANTITY_FRACTIONS)[index];
  }

  stopBubbling(e) {
    e.stopPropagation();
  }

  render() {
    const { quantities, isQuant } = this.state;
    const { category, QUANTITY_TYPES, item } = this.props;

    let { currentUnit = IMPERIAL } = this.props;
    currentUnit = currentUnit.toLowerCase();

    return (
      <div className="picker" onClick={this.stopBubbling}>
        <div className="picker-background" />
        <div className="picker-container">
          <span className="y-icon close-btn" data-icon={icons.xLarge} role="button" onClick={this.close}/>
          <div className="ingredient-title greyscale-1 font-size-base truncate-single-line">
            {quantities && parseInt(quantities.quantity, 10) !== 0 &&
              <span className="p1-text">{quantities.quantity || item['quantity-number']}</span>}
            {quantities && quantities.fraction !== '0' &&
              <span className="p1-text">{' ' + quantities.fraction }</span>}
            {<span className="p1-text">{quantities ? quantities.unit : (item['unit'] && item['unit'].trim() === UNIT_NONE ? '' : item['unit'])}</span>}
            <span className="p1-text">{item && firstCap(item['description'])}</span>
          </div>
          <div className="ingredient-categories">
            <div className={`ingredient-category font-bold p2-text ${isQuant ? 'active' : ''}`} onClick={this.clickCategory}>
              {CATEGORIES.QUANTITY}
            </div>
            {
              category &&
                <div className={`ingredient-category font-bold p2-text ${!isQuant ? 'active' : ''}`} onClick={this.clickCategory}>
                  {CATEGORIES.CATEGORY}
                </div>
            }
          </div>
          <div className="ingredient-content">
            <div className="picker-select-box" />
            {
              isQuant
                ? <div className="ingredient-quant-units">
                  <div className="ingredient-number" onScroll={this.scrollNumber} ref={this.numberPicker}>
                    <span className="ingredient-empty-head"/>
                    {
                      (new Array(101).fill(0).map((v, i) => {
                        const isActiveValue = quantities && i === Number(quantities.quantity);
                        const cls = classNames({
                          'h4-text': true,
                          active: isActiveValue,
                        });
                        return <span className={cls} key={i} order={i} value={i}>{i}</span>;
                      }))
                    }
                    <span className="ingredient-empty-tail"/>
                  </div>
                  <div className="ingredient-fraction" onScroll={this.scrollFraction} ref={this.fractionPicker}>
                    <span className="ingredient-empty-head"/>
                    {
                      Object.keys(QUANTITY_FRACTIONS).map((k, i) => {
                        const isActiveValue = quantities && k === quantities.fraction;
                        const cls = classNames({
                          'h4-text': true,
                          active: isActiveValue,
                        });
                        return <span className={cls} order={i} key={k}>{k}</span>;
                      })
                    }
                    <span className="ingredient-empty-tail"/>
                  </div>
                  <div className="ingredient-unit" onScroll={this.scrollUnit} ref={this.unitPicker}>
                    <span className="ingredient-empty-head"/>
                    {
                      QUANTITY_TYPES && QUANTITY_TYPES[currentUnit] && QUANTITY_TYPES[currentUnit].map((v, i) => {
                        const abbr = v[DISPLAY_VALUE];
                        const isActiveUnit = quantities && quantities.unit && abbr.toUpperCase() === quantities.unit.toUpperCase();
                        const cls = classNames({
                          'h4-text': true,
                          active: isActiveUnit,
                        });
                        return <span className={cls} key={abbr} order={i} value={abbr}>{abbr}</span>;
                      })
                    }
                    <span className="ingredient-empty-tail"/>
                  </div>
                </div>
                : category &&
                  <div className="ingredient-content-category" onScroll={this.scrollCategory} ref={this.categoryPicker}>
                    <span className="ingredient-empty-head"/>
                    {
                      Object.values(category).map((v, i) => {
                        const isActiveUnit = v.category.toUpperCase() === item[CATEGORY_KEY].toUpperCase();
                        const cls = classNames({
                          'h4-text': true,
                          'category-option': true,
                          active: isActiveUnit,
                        });
                        return (
                          <span
                            className={cls}
                            key={v.categoryId}
                            data-category-id={v.categoryId}
                            order={i}
                            value={v.category}>
                            {firstCap(v.category)}
                          </span>
                        );
                      })
                    }
                    <span className="ingredient-category-empty-tail"/>
                  </div>
            }
          </div>
          <div className="button-wrapper">
            <Button className="btn-primary save" onClick={this.save}>{'Save'}</Button>
          </div>
        </div>
      </div>
    );
  }
}

Picker.propTypes = {
  category: YummlyPropTypes.arrayOfObjects,
  clearShoppingModal: YummlyPropTypes.action,
  currentUnit: YummlyPropTypes.string,
  logMixpanelEvent: YummlyPropTypes.action,
  logTrackingEvents: YummlyPropTypes.action,
  QUANTITY_TYPES: YummlyPropTypes.quantity_types,
  setEditItem: YummlyPropTypes.action,
  setUpdatedShoppingItem: YummlyPropTypes.action,
  shoppingList: YummlyPropTypes.shoppingList,
  pickerType: YummlyPropTypes.string,
  showZendesk: YummlyPropTypes.action,
  syncShoppingList: YummlyPropTypes.action,
  toggleMobilePicker: YummlyPropTypes.action,
  item: YummlyPropTypes.shoppingItem,
  mixpanelEvents: YummlyPropTypes.arrayOfObjects,
  DDEEvents: YummlyPropTypes.arrayOfObjects,
};

const mapStateToProps = (state) => {
  return {
    category: ingredientCategoriesSelector(state),
    currentUnit: state.recipe.units,
    QUANTITY_TYPES: state.metadata[PICKER_UNIT_KEY],
    shoppingList: state.shoppingList,
    pickerType: deepProperty(state, 'app.showModal.modalProps.type'),
  };
};

const mapDispatchToProps = {
  clearShoppingModal,
  logMixpanelEvent: logEvent,
  logTrackingEvents,
  setEditItem,
  setUpdatedShoppingItem,
  showZendesk,
  syncShoppingList,
  toggleMobilePicker,
};

export default connect(mapStateToProps, mapDispatchToProps)(Picker);
