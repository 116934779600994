import React from 'react';

import { connect } from 'react-redux';
import { closeModal } from 'actions/Modal';
import { logout } from 'actions/Auth';
import ModalButton from './ModalButton/ModalButton';
import { icons } from 'constants/FontFaceCharacters';
import classNames from 'util/classNames';
import { deepProperty } from 'util/object';
import Legalese from 'components/Legalese';
import { URB_PAGE } from 'util/location';

function ModalOverlay(props) {
  if (!props.modal.shouldShow) {
    return null;
  }

  function onButtonClick(idx) {
    props.closeModal();
    const clickHandler =
      typeof idx === 'number' &&
      deepProperty(props, 'modal.buttons[' + idx + '].clickHandler');
    if (typeof clickHandler === 'function') {
      clickHandler();
    }
  }

  function handleCloseButtonClick() {
    const { modal, logout, closeModal } = props;

    // Logout existing customer if they refuse to accept latest
    // terms of use and privacy notice
    if (modal.modalType === 'policyAcceptance') {
      logout({
        keepModal: false, // and explicitly prompt the user they've been logged out
      });
    }

    if (modal.onCloseButtonClick) {
      modal.onCloseButtonClick();
    }

    closeModal();
  }

  function modalButtons() {
    const buttons = deepProperty(props, 'modal.buttons', []).map(
      (button, idx) => {
        return (
          <ModalButton
            key={idx}
            buttonIndex={idx}
            text={button.text}
            handleButtonClick={onButtonClick}
            className={ button.type === "secondary" ? "btn-light" : "btn-primary" }
          />
        );
      }
    );
    return Boolean(buttons.length) && (
      <div className="buttons">{buttons}</div>
    );
  }

  const { modal, pageLocations } = props;
  let subHeader;

  if (pageLocations && pageLocations[URB_PAGE]) {
    return null;
  }


  if (typeof modal.subheading === 'string') {
    subHeader = <h2>{modal.subheading}</h2>;
  } else if (modal.subheading) {
    subHeader = modal.subheading.map((sub, idx) => {
      return (
        <sub.tag key={idx} {...sub.props}>
          {sub.text}
        </sub.tag>
      );
    });
  }

  const cls = classNames({
    'modal-overlay': true,
    [`modal-type-${modal.modalType}`]: true,
  });

  return (
    <div key="modal-overlay" className={cls}>
      <div className="modal-content">
        <div className="top">
          <h1>{modal.title}</h1>
          {subHeader}
          {modalButtons()}
          {modal.includeCloseButton && (
            <span
              className="y-icon cancel-button"
              data-icon={icons.xLarge}
              onClick={handleCloseButtonClick}
            />)}
          {modal.modalType === 'policyAcceptance' &&
            <Legalese forceCheckbox={true} />
          }
        </div>
      </div>
    </div>
  );
}

ModalOverlay.propTypes = {
  modal: YummlyPropTypes.modal,
  closeModal: YummlyPropTypes.action,
  logout: YummlyPropTypes.action,
  pageLocations: YummlyPropTypes.pageLocations,
};

const mapStateToProps = state => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = {
  closeModal,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverlay);
