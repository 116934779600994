import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

function ModalButton(props) {
  function handleButtonClick() {
    props.handleButtonClick(props.buttonIndex);
  }

  return (
    <Button className={props.className} onClick={handleButtonClick}>
      <span>{props.text}</span>
    </Button>
  );
}

ModalButton.propTypes = {
  buttonIndex: PropTypes.number,
  text: PropTypes.string,
  handleButtonClick: PropTypes.func,
  className: PropTypes.string,
};

export default ModalButton;
