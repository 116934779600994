// P0020 • Sticky Action
import React from 'react';

import Button from 'components/Pasta/Button';


const StickyAction = ({ actionLabel, onClick }) => {
  return (
    <div className="sticky-action flex shrink-0">
      <Button
        label={actionLabel}
        contained={true}
        onClick={onClick}
      />
    </div>
  );
};

StickyAction.propTypes = {
  actionLabel: YummlyPropTypes.string,
  onClick: YummlyPropTypes.func,
};

export default StickyAction;
