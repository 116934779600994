import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import { icons } from 'constants/FontFaceCharacters';
import { bindInstance } from 'util/dev';
import { toggleVirtualPantryPromoModal } from 'actions/App';
import { logEvent } from 'actions/mixpanel';
import { setPlanAndShopModalShown } from 'actions/MealPlanning';
import { PANTRY_SEARCH_ROUTE, VIRTUAL_PANTRY_SUPPORT_ROUTE } from 'constants/routes';
import { TRACKING, DDE } from 'constants/Tracking';
import Link from 'components/Link';
import { currentUserSelector } from 'selectors/auth';
import { logDDEEvent } from 'actions/DDE';
import { toggleLoginModal } from 'actions/App';
import { setVirtualPantryModalShown, setPantryEntrypoint, ENTRYPOINT_PROMO } from 'actions/VirtualPantry';
import { getStringFunction } from 'util/localStrings';
import { VIRTUAL_PANTRY_SEARCH_PAGE } from 'util/location';
import storage from 'util/storage';
import { VIRTUAL_PANTRY_PROMO_KEY } from 'constants/BigModalKeys';

const str = getStringFunction([{
  id: 'pantry-search.button.start',
  defaultMessage: 'Start a Pantry Search',
}, {
  id: 'pantry-search.button.more',
  defaultMessage: 'Learn More',
}, {
  id: 'pantry-search.title',
  defaultMessage: 'Shopping less? Cooking more? We can help.',
}, {
  id: 'pantry-search.para.1',
  defaultMessage: 'Wish there was an easy way to find recipes that you can make without going to the store? Tired of wasting food that you forgot to use?',
}, {
  id: 'pantry-search.para.2',
  defaultMessage: 'See what you can make today using the ingredients you have at home with our new Pantry-Ready recipe search.',
}], 'pantry-search');

export class VirtualPantryPromo extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this);
    this.button = React.createRef();
  }

  componentDidMount() {
    if (this.button.current) {
      this.intersectionObserver = new IntersectionObserver(this.trackImpression, {
        threshold: 0.5,
      });
      this.intersectionObserver.observe(this.button.current);
    }
  }

  onDismissClick() {
    const {
      isLoggedIn,
      setVirtualPantryModalShown,
      toggleVirtualPantryPromoModal,
    } = this.props;
    if (isLoggedIn) {
      setVirtualPantryModalShown();
    }
    storage.perm.set(VIRTUAL_PANTRY_PROMO_KEY, true);
    toggleVirtualPantryPromoModal(false);
  }

  onLearnMoreClick() {
    const {
      isLoggedIn,
      setVirtualPantryModalShown,
      toggleVirtualPantryPromoModal,
      track,
    } = this.props;
    if (isLoggedIn) {
      setVirtualPantryModalShown();
    }
    track(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.PANTRY_SEARCH_PROMO,
      'Prompt Action': str('button.more'),
      'Prompt Text': str('button.more'),
    });
    storage.perm.set(VIRTUAL_PANTRY_PROMO_KEY, true);
    toggleVirtualPantryPromoModal(false);
  }

  onCtaClick(e) {
    const {
      isLoggedIn,
      pageLocations,
      setPantryEntrypoint,
      setVirtualPantryModalShown,
      toggleVirtualPantryPromoModal,
      track,
    } = this.props;
    e.preventDefault();
    if (isLoggedIn) {
      setVirtualPantryModalShown();
    }
    track(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.PANTRY_SEARCH_PROMO,
      'Prompt Action': str('button.start'),
      'Prompt Text': str('button.start'),
    });
    this.props.logDDEEvent(DDE.CTA_CLICK, {
      action_type: 'pantry_search_introduction',
      button_text: str('button.start'),
      floating: false,
      pop_up: true,
    });
    storage.perm.set(VIRTUAL_PANTRY_PROMO_KEY, true);
    toggleVirtualPantryPromoModal(false);
    setPantryEntrypoint(ENTRYPOINT_PROMO);

    if (!pageLocations[VIRTUAL_PANTRY_SEARCH_PAGE]) {
      this.props.history.push(PANTRY_SEARCH_ROUTE);
    }
  }

  trackImpression(entries) {
    entries.forEach(({ isIntersecting }) => {
      if (isIntersecting) {
        const { logDDEEvent, track } = this.props;

        track(TRACKING.PROMPT_VIEW, {
          'Prompt Type': TRACKING.PANTRY_SEARCH_PROMO,
        });

        logDDEEvent(DDE.CTA_IMPRESSION, {
          action_type: 'pantry_search_introduction',
          button_text: str('button.start'),
          floating: false,
          pop_up: true,
        });

        this.intersectionObserver.disconnect();
      }
    });
  }

  render() {
    return (
      <div className="virtual-pantry-promo">
        <span
          className="y-icon cancel greyscale-1 p3-text"
          data-icon={icons.xSmall}
          onClick={this.onDismissClick}
        />
        <div className="virtual-pantry-content">
          <h1 className="font-bold gr-title h2-text primary-dark">{str('title')}</h1>
          <h5 className="font-regular gr-desc p2-text greyscale-2">{str('para.1')}</h5>
          <h5 className="font-regular gr-desc p2-text greyscale-2">{str('para.2')}</h5>
          <div className="starter-wrapper" ref={this.button}>
            <Link
              className="btn-primary get-started"
              href={PANTRY_SEARCH_ROUTE}
              onClick={this.onCtaClick}
            >
              {str('button.start')}
            </Link>
            <Link
              className="learn-more font-bold p3-text primary-teal"
              onClick={this.onLearnMoreClick}
              href={VIRTUAL_PANTRY_SUPPORT_ROUTE}
            >
              {str('button.more')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

VirtualPantryPromo.propTypes = {
  isLoggedIn: YummlyPropTypes.bool,
  logDDEEvent: YummlyPropTypes.action,
  toggleVirtualPantryPromoModal: YummlyPropTypes.action,
  setVirtualPantryModalShown: YummlyPropTypes.action,
  track: YummlyPropTypes.action,
  pageLocations: YummlyPropTypes.pageLocations,
  setPantryEntrypoint: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
};

const mapStateToProps = state => {
  return {
    isLoggedIn: Boolean(currentUserSelector(state)),
    pageLocations: state.pageLocations,
  };
};

const mapDispatchToProps = {
  logDDEEvent,
  toggleVirtualPantryPromoModal,
  track: logEvent,
  setPlanAndShopModalShown,
  toggleLoginModal,
  setVirtualPantryModalShown,
  setPantryEntrypoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VirtualPantryPromo));
