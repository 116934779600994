import React, { useEffect } from 'react';

import { currentUserSelector } from 'selectors/auth';
import { getCollection } from 'actions/Collection';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import Spinner from 'components/Spinner';
import CollectionEmptyState from 'components/CollectionEmptyState/CollectionEmptyState';

const MealPlanAppointmentCard = loadable(() => {
  return import(/* webpackChunkName: "mealPlanning" */ 'bundles/mealPlanning/components/MealPlanAppointmentCard');
});

const ScheduleRecipeModalSelectRecipeScene = ({
  selectedCollectionToSchedule,
  currentUser,
  collection,
  getCollection,
}) => {

  useEffect(() => {
    getCollection(currentUser.userName, selectedCollectionToSchedule);
  }, []);

  const { loaded, recipes } = collection;

  return <div className="schedule-modal-collection-recipe-cards" >
    {
      loaded
        ? <div className="select-recipe-cards">
          {
            recipes?.length
              ? recipes?.map((recipe, idx) => {
                return <React.Fragment key={idx}>
                  <MealPlanAppointmentCard
                    recipeData={recipe}
                    selectable={true}
                  />
                </React.Fragment>;
              }) : <CollectionEmptyState/>
          }
        </div>
        : <Spinner mode="light" />
    }
  </div>;
};

ScheduleRecipeModalSelectRecipeScene.propTypes = {
  selectedCollectionToSchedule: YummlyPropTypes.objectOrString,
  currentUser: YummlyPropTypes.objectOrString,
  collection: YummlyPropTypes.objectOrString,
  getCollection: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    collection: state.collection,
    currentUser: currentUserSelector(state),
    selectedCollectionToSchedule: state.mealPlanning.appointments.selectedCollectionToSchedule,
  };
};

const mapDispatchToProps = {
  getCollection,
};


export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRecipeModalSelectRecipeScene);
