import { getStringFunction } from 'util/localStrings';
import { DEFAULT } from 'constants/Experiments';

export const getCopy = getStringFunction(
  [
    {
      id: `mealPlanner.title.${DEFAULT}`,
      defaultMessage: "Welcome to Plan & Shop",
    },
    {
      id: `mealPlanner.description.${DEFAULT}`,
      defaultMessage: "Get dinner on the table with ease using Yummly's integrated shopping list and meal planner.",
    },
    {
      id: `mealPlanner.benefits.one.${DEFAULT}`,
      defaultMessage: "Get personalized meal plan recommendations",
    },
    {
      id: `mealPlanner.benefits.two.${DEFAULT}`,
      defaultMessage: "Add ingredients for planned meals with a click",
    },
    {
      id: `mealPlanner.benefits.three.${DEFAULT}`,
      defaultMessage: "Take your list on the go or order groceries for delivery",
    },
    {
      id: `mealPlanner.actionText.${DEFAULT}`,
      defaultMessage: "Try It Now",
    },
    {
      id: `mealPlanner.learnMore.${DEFAULT}`,
      defaultMessage: "Learn More",
    },
  ],
  'mealPlanner'
);
