import React, { useState, useEffect } from 'react';

import Icon from 'components/Pasta/Icon';
import { connect } from 'react-redux';
import { str } from 'constants/MealPlanning';
import { toggleCookTimeModal } from "actions/MealPlanning";
import StickyAction from 'components/Pasta/StickyAction';
import { selectedAppointmentSelector, selectedAppointmentDateSelector } from "selectors/mealPlanning";
import moment from 'moment';
import { YYYY_MM_DD_DateFormat } from 'util/timestamp';
import { updateAppointment } from "actions/MealPlanning";
import { friendlyDateLong } from 'util/timestamp';
import MealPlanModal from 'components/MealPlanModal/MealPlanModal';
import { pstIcons } from 'constants/FontFaceCharacters';
import MealPlanAppointmentCard from 'bundles/mealPlanning/components/MealPlanAppointmentCard/MealPlanAppointmentCard';
import { extractAppointmentDisplayInfo } from 'util/mealPlanning';

const strings = {
  TITLE: 'Set Meal Time',
  SUBHEADING: 'Choose a time to serve up, and set reminders for when to begin preparation',
};

const TimeCell = ({
  title,
  time,
}) => {
  return <div className="cell">
    <div className="pst-paragraph-regular uppercase cell-time">
      {title}
    </div>
    <div className="pst-eyebrow-strong">
      {time}
    </div>
  </div>;
};

TimeCell.propTypes = {
  title: YummlyPropTypes.string,
  time: YummlyPropTypes.string,
};


const PrepCookEat = ({ selectedAppointmentDate, eatTime, prepStartTime }) => {
  const eatMoment = moment(eatTime);
  const eatDisplayString = eatMoment?.isValid() ? eatMoment.format("LT") : '-';

  const prepStartMoment = moment(prepStartTime);
  const prepDisplayString = prepStartMoment?.isValid() ? prepStartMoment.format("LT") : '-';

  return <div className="prep-cook-eat">
    <div className="pst-p-small-regular uppercase selected-date">{friendlyDateLong(selectedAppointmentDate)}</div>
    <div className="cells">
      <TimeCell title={str('start')} time={prepDisplayString}/>
      <div className="divider flex">
        <div className="pst-icon" data-icon={pstIcons.F0003_013__Icon_overflow}/>
        <div className="pst-icon timer" data-icon={pstIcons.F0003_023__Icon_time_elapsed}/>
        <div className="pst-icon" data-icon={pstIcons.F0003_013__Icon_overflow}/>
      </div>
      <TimeCell title={str('serve')} time={eatDisplayString}/>
    </div>
  </div>;
};

PrepCookEat.propTypes = {
  selectedAppointmentDate: YummlyPropTypes.string,
  eatTime: YummlyPropTypes.objectOrString,
  prepStartTime: YummlyPropTypes.objectOrString,
};

const MealPlannerCookTimeModal = ({ toggleCookTimeModal, selectedAppointment, selectedAppointmentDate, updateAppointment }) => {
  const { recipeCookDuration } = extractAppointmentDisplayInfo(selectedAppointment);
  const [eatTime, setEatTime] = useState(moment(selectedAppointment?.['cook-time']).add(recipeCookDuration?.value, recipeCookDuration?.unit));
  const [prepStartTime, setPrepStartTime] = useState(selectedAppointment?.['cook-time']);

  useEffect(() => {
    const prepMoment = moment(eatTime);
    const prepMomentMoveBack = prepMoment.add(`-${recipeCookDuration?.value}`, recipeCookDuration?.unit);
    setPrepStartTime(prepMomentMoveBack);
  }, [eatTime]);

  function onClickDone() {
    const prepStartTimeFormatted = prepStartTime.format();
    const indexOfTimeSubstring = prepStartTimeFormatted.indexOf("T");
    const timeWithTimeZone = prepStartTimeFormatted.slice(indexOfTimeSubstring + 1);

    if (prepStartTimeFormatted !== selectedAppointment?.['cook-time']) {
      const newAppointmentDetails = {
        'cook-time': timeWithTimeZone,
      };

      updateAppointment(selectedAppointment?.id, newAppointmentDetails);
    }
    toggleCookTimeModal(false);
  }

  function onTimeChange(e) {
    const eatMoment = moment(moment(selectedAppointmentDate).format(YYYY_MM_DD_DateFormat));
    const timeString = e.target.value;
    const [hours, minutes] = timeString.split(":");

    eatMoment.set({
      "hour": hours,
      "minute": minutes,
    });

    setEatTime(eatMoment);
  }

  return (
    <MealPlanModal
      title={strings.TITLE}
      subheading={strings.SUBHEADING}
    >
      <>
        <div className="content">
          <MealPlanAppointmentCard
            appointmentData={selectedAppointment}
            hideOptions={true}
            preventNav={true}
          />
          <PrepCookEat
            selectedAppointmentDate={selectedAppointmentDate}
            eatTime={eatTime}
            prepStartTime={prepStartTime}
          />
          <div className="time-root">
            <div className="time-input-container">
              <input type = "time" className="time-input" onChange={onTimeChange}/>
              <div className="divot" >
                <Icon className="arrow-down" iconName={'downArrowSmall'} />
              </div>
            </div>
          </div>
        </div>
        <StickyAction
          actionLabel={str('done')}
          onClick={onClickDone}
        />
      </>
    </MealPlanModal>
  );
};

MealPlannerCookTimeModal.propTypes = {
  toggleCookTimeModal: YummlyPropTypes.action,
  selectedAppointment: YummlyPropTypes.objectOrString,
  selectedAppointmentDate: YummlyPropTypes.string,
  updateAppointment: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    selectedAppointment: selectedAppointmentSelector(state),
    selectedAppointmentDate: selectedAppointmentDateSelector(state),
  };
};

const mapDispatchToProps = {
  toggleCookTimeModal,
  updateAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerCookTimeModal);
