import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleLoginModal, toggleProInternationalModal } from 'actions/App';
import { logEvent } from 'actions/mixpanel';

import Button from 'components/Button';
import Image from 'components/Image';

import { icons } from 'constants/FontFaceCharacters';

import { HOME_ROUTE } from 'constants/routes';
import { TRACKING } from 'constants/Tracking';

import { currentUserSelector } from 'selectors/auth';
import { hasProRecipeAccess } from 'selectors/paywall';
import { getProHomeLink } from 'selectors/pro';

import cdn from 'util/cdn';
import { bindInstance } from 'util/dev';
import { getStringFunction } from 'util/localStrings';
import {
  EXTERNAL_SEARCH_PAGE,
  HOME_PAGE,
  INTERNAL_SEARCH_PAGE,
  PRO_CHECKOUT_PAGE,
  PROFILE_COLLECTION_PAGE,
  PROFILE_PAGE,
  SOURCE_PAGE,
} from 'util/location';
import { deepProperty } from 'util/object';

const str = getStringFunction(
  [
    {
      id: 'proInternationalModal.title',
      defaultMessage: 'Yummly subscription unavailable',
    },
    {
      id: 'proInternationalModal.subtitle',
      defaultMessage: 'We’re sorry. Yummly subscription is unavailable in your region at this time. Please check later.',
    },
    {
      id: 'proInternationalModal.button',
      defaultMessage: 'OK',
    },
    {
      id: 'proInternationalModal.unavailableForRegion',
      defaultMessage: 'Yummly Pro Unavailable for Region',
    },
  ],
  'proInternationalModal',
);

class ProInternationalModal extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  componentDidMount() {
    const { hasProRecipeAccess, logMixpanelEvent } = this.props;

    if (this.props.hasProRecipeAccess) {
      this.props.toggleProInternationalModal(false);
    }

    if (!hasProRecipeAccess) {
      logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': str('unavailableForRegion'),
      });
    }
  }

  componentDidUpdate() {
    if (this.props.hasProRecipeAccess) {
      this.props.toggleProInternationalModal(false);
    }
  }

  closeModal() {

    const {
      pageLocations,
      logMixpanelEvent,
      toggleProInternationalModal,
      toggleLoginModal,
      hasProRecipeAccess,
      countryName,
      previousLocation,
      countryProStatus,
      currentUser,
    } = this.props;

    const isAuthenticated = (Boolean(currentUser) && Boolean(currentUser.email));

    toggleProInternationalModal(false);
    logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': str('unavailableForRegion'),
      'Prompt Action': 'Ok',
      'Region': countryName || "",
    });

    if (pageLocations[PRO_CHECKOUT_PAGE] && !isAuthenticated) {
      toggleLoginModal(true);
      return;
    }

    // By default, redirect all non-region supported users to yummly home page
    // EXCEPT users on the checkout page or already on the yummly home page
    if (!pageLocations[PRO_CHECKOUT_PAGE] &&
        !pageLocations[HOME_PAGE] &&
        !pageLocations[INTERNAL_SEARCH_PAGE] &&
        !pageLocations[EXTERNAL_SEARCH_PAGE] &&
        !pageLocations[PROFILE_PAGE] &&
        !pageLocations[SOURCE_PAGE] &&
        !pageLocations[PROFILE_COLLECTION_PAGE]) {

      if (countryProStatus && previousLocation) {
        return previousLocation ? this.props.history.goBack() : this.props.history.push(getProHomeLink(hasProRecipeAccess));
      }

    }

    return this.props.history.push(HOME_ROUTE);
  }

  render() {
    if (this.props.hasProRecipeAccess) {
      return null;
    }
    return (
      <div className="pro-international-modal background-light">
        <span className="y-icon closeX" data-icon={icons.xLarge} role="button" onClick={this.closeModal}/>
        <Image
          src={`${cdn.imagePrefix()}/yummly.svg`}
          alt={'Yummly'}
          className="logo-image"
        />
        <h3 className="modal-title h4-text">{str('title')}</h3>
        <p className="modal-subtitle p2-text">{str('subtitle')}</p>
        <Button className="btn-primary" onClick={this.closeModal}>
          { str('button') }
        </Button>
      </div>
    );
  }
}
ProInternationalModal.propTypes = {
  countryName: YummlyPropTypes.string,
  countryProStatus: YummlyPropTypes.bool,
  currentUser: YummlyPropTypes.currentUser,
  hasProRecipeAccess: YummlyPropTypes.bool,
  history: YummlyPropTypes.history,
  logMixpanelEvent: YummlyPropTypes.action,
  pageLocations: YummlyPropTypes.pageLocations,
  previousLocation: YummlyPropTypes.previousLocation,
  toggleLoginModal: YummlyPropTypes.action,
  toggleProInternationalModal: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    countryName: state.app.countryName,
    countryProStatus: state.app.countryProStatus,
    currentUser: currentUserSelector(state),
    hasProRecipeAccess: hasProRecipeAccess(state),
    pageLocations: state.pageLocations,
    previousLocation: deepProperty(state, 'app.previousLocation'),
  };
};
const mapDispatchToProps = {
  logMixpanelEvent: logEvent,
  toggleLoginModal,
  toggleProInternationalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProInternationalModal));
