import MealPlanModal from 'components/MealPlanModal/MealPlanModal';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { str } from 'constants/MealPlanning';
import { recommendedMealsSelector } from 'selectors/mealPlanning';
import { fetchRecommendedMeals, scheduleSelectedRecipes, closeModal } from "actions/MealPlanning";

import Spinner from 'components/Spinner';
import StickyAction from 'components/Pasta/StickyAction';
import loadable from '@loadable/component';

const MealPlanAppointmentCard = loadable(() => {
  return import(/* webpackChunkName: "mealPlanning" */ 'bundles/mealPlanning/components/MealPlanAppointmentCard');
});

const MealPlannerDiscoveryModal = ({ recommendedMeals, fetchRecommendedMeals, scheduleSelectedRecipes, closeModal }) => {
  const displayCarousels = recommendedMeals?.carousels?.length && recommendedMeals?.carousels?.slice(0, 3);

  useEffect(() => {
    if (!recommendedMeals.fetching && recommendedMeals?.carousels?.length < 3) {
      fetchRecommendedMeals();
    }
  }, [recommendedMeals.fetching]);

  function onClickDone() {
    scheduleSelectedRecipes();
    closeModal();
  }

  return (
    <MealPlanModal
      title={'Browse Recipes'}
      subheading={'Your personalized recipe recommendations'}
    >
      <>
      {
      displayCarousels?.length
        ? <div className="recommended-cards">
          {displayCarousels?.map((carousel) => {
            return <div className="recommended-set" key={carousel.carouselName}>
              <div className="pst-h7 category-title">{carousel.carouselName}</div>
              {carousel.newList?.map((recipeData, idx) => {
                return <React.Fragment key={idx}>
                  <MealPlanAppointmentCard
                    recipeData={recipeData}
                    selectable={true}
                  />
                </React.Fragment>;
              })}

            </div>;
          })}
        </div>
        : <Spinner mode="light"/>
      }
      <StickyAction
        actionLabel={str('done')}
        onClick={onClickDone}
      />
      </>
    </MealPlanModal>
  );
};

MealPlannerDiscoveryModal.propTypes = {
  recommendedMeals: YummlyPropTypes.objectOrString,
  fetchRecommendedMeals: YummlyPropTypes.action,
  scheduleSelectedRecipes: YummlyPropTypes.action,
  closeModal: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    recommendedMeals: recommendedMealsSelector(state),
  };
};

const mapDispatchToProps = {
  fetchRecommendedMeals,
  scheduleSelectedRecipes,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlannerDiscoveryModal);

