import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cdn from 'util/cdn';
import { format } from 'util';
import classNames from 'util/classNames';
import { deepProperty } from 'util/object';
import { bindInstance } from 'util/dev';
import { icons } from 'constants/FontFaceCharacters';

import Image from 'components/Image';
import Button from 'components/Button';
import { cancelSubscription, closeAllModal } from 'actions/Subscription';
import { str } from './ProGenericModalCopy';

import { TRACKING, DDE } from 'constants/Tracking';
import { logEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';

import { CHECKOUT_SOURCES } from 'constants/Pro';
import { DEFAULT } from 'constants/Experiments';
import { STRIPE_STATUS, STRIPE_ERROR_CODE } from 'constants/index';

// UPDATE: 'UPDATE',
// SUCCESS: 'SUCCESS',
// PAYMENT_FAIL: 'PAYMENT_FAIL',
// SYSTEM_FAIL: 'SYSTEM_FAIL',
// REMOVE_PAYMENT: 'REMOVE_PAYMENT',
// PLATFORM_FAIL: 'PLATFORM_FAIL',
// ALREADY_PRO_MEMBER: 'ALREADY_PRO_MEMBER',
// UNABLE_TO_REMOVE_ACCOUNT: 'UNABLE_TO_REMOVE_ACCOUNT',

export class ProGenericModal extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      ref: ['button'],
    });
    this.modal = null;
  }

  componentDidMount() {
    const {
      proModalType,
      attributionSource,
    } = this.props;

    const mpProp = {
      'Subscription LP Version': 'general',
      'Lead Generating Type': attributionSource,
      'Prompt Type': str(`subscriptionTitle.${DEFAULT}`),
    };

    if (proModalType === STRIPE_STATUS.SUCCESS) { // Subscription Successful Popup
      this.trackView(mpProp);
    }
  }

  getContent() {
    const {
      subscription,
      proModalType,
      stripeErrorCode,
    } = this.props;

    let title = "", subtitle = "";

    if (proModalType === STRIPE_STATUS.SUCCESS) { // Subscription Successful Popup
      title = str(`subscriptionTitle.${DEFAULT}`);
      subtitle = str(`subscriptionSubtitle.${DEFAULT}`);
    } else if (proModalType === STRIPE_STATUS.UPDATE) { // Update Successful Popup
      title = str(`updateTitle.${DEFAULT}`);
      subtitle = str(`updateSubtitle.${DEFAULT}`);
    } else if (proModalType === STRIPE_STATUS.PAYMENT_FAIL) {
      switch (stripeErrorCode) {
        case STRIPE_ERROR_CODE.CARD_DECLINED:
          title = str('cardDeclined.title');
          subtitle = str('cardDeclined.subtitle');
          break;
        case STRIPE_ERROR_CODE.EXPIRED_CARD:
          title = str('cardExpired.title');
          subtitle = str('cardExpired.subtitle');
          break;
        case STRIPE_ERROR_CODE.INCORRECT_CVC:
          title = str('incorrectCVC.title');
          subtitle = str('incorrectCVC.subtitle');
          break;
        case STRIPE_ERROR_CODE.INCORRECT_NUMBER:
          title = str('incorrectNumber.title');
          subtitle = str('incorrectNumber.subtitle');
          break;
      }
    } else if (proModalType === STRIPE_STATUS.CANCEL) { // Cancel Popup
      title = str(`cancelTitle.${DEFAULT}`);
      subtitle = str(`cancelSubtitle.${DEFAULT}`);
    } else if (proModalType === STRIPE_STATUS.SYSTEM_FAIL) {
      title = str(`paymentFailureTitle.${DEFAULT}`);
      subtitle = str(`systemFailSubtitle.${DEFAULT}`);
    } else if (proModalType === STRIPE_STATUS.REMOVE_PAYMENT) { // Remove Payment method successful Popup
      title = str(`deletePaymentTitle.${DEFAULT}`);
      subtitle = str(`deletePaymentSubtitle.${DEFAULT}`);
    } else if (proModalType === STRIPE_STATUS.PLATFORM_FAIL) {
      const appStore = subscription['current-vendor'] === 'google' ? 'Google Play' : 'Apple',
        device = subscription['current-vendor'] === 'google' ? 'Android' : 'Apple';
      title = str(`platformFailureTitle.${DEFAULT}`);
      subtitle = format(str(`platformFailureSubtitle.${DEFAULT}`), appStore, device);
    } else if (proModalType === STRIPE_STATUS.UNABLE_TO_REMOVE_ACCOUNT) {
      title = str(`unableToRemoveAccountTitle.${DEFAULT}`);
      subtitle = str(`unableToRemoveAccountSubtitle.${DEFAULT}`);
    }

    return {
      title,
      subtitle,
    };
  }

  trackView(mpProp) {
    this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, mpProp);
  }

  handleCloseModal() {
    const mpProp = {
      "Prompt Type": this.modal.title,
    };
    if (this.props.proModalType === STRIPE_STATUS.SUCCESS) {
      mpProp["Prompt Action"] = 'Initiate Yummly Pro';
      mpProp["Prompt Label"] = deepProperty(this.ref.button.current, 'button.current.innerText');
    } else {
      mpProp["Prompt Action"] = 'Close Dialog Box';
    }

    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, mpProp);
    this.props.closeAllModal();
  }

  // --- Cancel Subscription Modal Starts --- //
  // confirm button on Cancel Subscription Modal
  handleConfirmCancel() {

    this.trackingCancelClick(str(`confirm.${DEFAULT}`));
    this.props.cancelSubscription();
    this.props.closeAllModal();
  }

  // dismiss button on Cancel Subscription Modal
  handleNotNow() {
    this.trackingCancelClick(str(`notNow.${DEFAULT}`));
    this.props.closeAllModal();
  }
  handleSurveySubmit() {
    this.props.closeAllModal();
  }

  trackingCancelClick(type) {
    const mpProp = {
        "Prompt Type": TRACKING.YUMMLY_PRO_CONFIRM_CANCEL,
        "Prompt Action": type,
      },
      ddeProp = {
        "action_type": DDE.SUBSCRIPTION_CANCEL_CONFIRMATION,
        "button_text": str(`confirm.${DEFAULT}`),
        "floating": false,
        "pop_up": true,
      };

    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, mpProp);
    this.props.logDDEEvent(DDE.CTA_CLICK, ddeProp);
  }
  // ---Cancel Subscription Modal Ends--- //

  render() {
    const { proModalType } = this.props,
      platformFailLink = (
        <React.Fragment>
          {` ${str(`platformFailureMoreInfo.${DEFAULT}`)} `}
          <a className="font-bold" href="https://help.yummly.com/hc/en-us/categories/360001964932-Yummly-Pro" target="_blank">{str(`platformFailFAQ.${DEFAULT}`)}</a>
          <span>{'.'}</span>
        </React.Fragment>
      );

    const classes = classNames({
      'pro-generic-modal background-light': true,
      'flex-column': true,
    });

    this.modal = this.getContent();

    return (
      <div className={classes}>
        <span
          className="y-icon closeX"
          data-icon={icons.xLarge}
          role="button"
          onClick={this.handleCloseModal}
          aria-label={'Close Dialog Box'}
          data-text={'Close Dialog Box'}
        />
        <Image
          src={`${cdn.imagePrefix()}/yummly.svg`}
          alt={'Yummly'}
          className="logo-image"
        />
        {
          proModalType === STRIPE_STATUS.CANCEL ? (
            <React.Fragment>
              <h3 className="modal-title h4-text">{this.modal.title}</h3>
              <p className="modal-subtitle p2-text">{this.modal.subtitle}</p>
              <div className="btn-group flex-row">
                <React.Fragment>
                  <Button className="btn-primary cancel" onClick={this.handleNotNow}>{str(`notNow.${DEFAULT}`)}</Button>
                  <Button className="btn-primary btn-light confirm" onClick={this.handleConfirmCancel}>{str(`confirm.${DEFAULT}`)}</Button>
                </React.Fragment>
              </div>
            </React.Fragment>) : (
            <React.Fragment>
              <h3 className="modal-title h4-text">{this.modal.title}</h3>
              <p className="modal-subtitle p2-text">
                {this.modal.subtitle}
                {proModalType === STRIPE_STATUS.PLATFORM_FAIL && platformFailLink}
              </p>
              {
                proModalType === STRIPE_STATUS.SUCCESS &&
                <div className="pro-modal-footer">
                  <Image
                    src={`${cdn.imagePrefix()}/subscribedModalFooter.jpg`}
                    alt={'Subscribed Success Footer Image'}
                    className="pro-modal-footer-image"
                  />
                </div>
              }
              <Button
                className="btn-primary email-submit"
                ref={this.ref.button}
                onClick={this.handleCloseModal}
              >
                {proModalType === STRIPE_STATUS.SUCCESS ? str(`startExplore.${DEFAULT}`) : str(`okay.${DEFAULT}`) }
              </Button>
            </React.Fragment>)
        }
      </div>
    );
  }
}
ProGenericModal.propTypes = {
  closeAllModal: YummlyPropTypes.action,
  cancelSubscription: YummlyPropTypes.action,
  subscription: YummlyPropTypes.subscription,
  logMixpanelEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
  proModalType: YummlyPropTypes.string.isRequired,
  attributionSource: YummlyPropTypes.string,
  stripeErrorCode: YummlyPropTypes.string,
};

const mapStateToProps = state => {
  return {
    proModalType: deepProperty(state, 'app.showModal.modalProps.proModalType'),
    stripeErrorCode: deepProperty(state, 'app.showModal.modalProps.errorCode'),
    subscription: state.pro.subscription,
    attributionSource: deepProperty(state, 'pro.attributionSource', CHECKOUT_SOURCES.default),
    proUser: deepProperty(state, 'auth.currentUser.proUser', {}),
  };
};

const mapDispatchToProps = {
  cancelSubscription,
  closeAllModal,
  logMixpanelEvent: logEvent,
  logDDEEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProGenericModal));
