import React from 'react';
import { connect } from 'react-redux';
import { str } from 'constants/MealPlanning';

import Cardlet from 'components/Pasta/Cardlet';

const ScheduleRecipeModalSelectCollectionScene = ({ collections, onCollectionClick }) => {
  return <div className="schedule-modal-collection-cards" > {
    collections?.map((collection) => {
      const subHead = collection.totalCount + " " + str('recipes');
      const recipes = collection.recipes;
      let thumbnailURL = '';
      if (recipes.length) {
        thumbnailURL = recipes[0];
      }

      function onCardletClick() {
        if (onCollectionClick) {
          onCollectionClick({
            url: collection.urlName || collection.id,
            friendlyName: collection.name,
          });
        }
      }

      return <Cardlet
        key={collection.name}
        title={collection.name}
        subhead={subHead}
        thumbnailURL={thumbnailURL}
        onClick={onCardletClick}
      />;
    })
  }</div>;
};

const mapStateToProps = (state) => {
  return {
    collections: state.myCollections,
  };
};

const mapDispatchToProps = {};


ScheduleRecipeModalSelectCollectionScene.propTypes = {
  collections: YummlyPropTypes.arrayOfObjects,
  onCollectionClick: YummlyPropTypes.action,
};



export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRecipeModalSelectCollectionScene);
