import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { toggleLegalModal } from 'actions/Subscription';
import Icon from 'components/Pasta/Icon';

const Policy = loadable(() => {
  return import(/* webpackChunkName: "staticPaths" */ 'bundles/staticPaths/components/Policy');
});

function LegalModal(props) {
  function closeModal() {
    const elem = document.querySelector('#payment-disclaimer-terms'); // from PaymentDisclaimer component to ensure it's still in view when modal closes
    if (elem && elem.scrollIntoViewIfNeeded) {
      elem.scrollIntoViewIfNeeded();
    }
    props.toggleLegalModal(false);
  }

  useEffect(() => {
    if (props.type === 'subscription' && __CLIENT__) {
      const elem = document.querySelector('#yummly-subscriptions');
      if (elem) {
        elem.scrollIntoView();
      }
    }
  }, []);

  return (
    <div className="legal-modal">
      <Icon iconName="xSmall" className="close-modal" onClick={closeModal} role="button" aria-label="close modal" />
      <Policy policyKey="terms" />
    </div>
  );
}

LegalModal.propTypes = {
  toggleLegalModal: YummlyPropTypes.action,
  type: YummlyPropTypes.string,
};

const mapDispatchToProps = {
  toggleLegalModal,
};

export default connect(null, mapDispatchToProps)(LegalModal);
