import React from 'react';

// import Button from 'components/Button';
import Button from 'components/Pasta/Button';
import Icon from 'components/Pasta/Icon';
import { MEAL_PLANNER_TAB_IDS, deleteAppointments } from 'actions/MealPlanning';
import { connect } from 'react-redux';
import { mealItemsSelector,
  selectedAppointmentDateSelector,
  selectedAppointmentDateRangeSelector,
  appointmentsForSelectedDateSelector,
  appointmentsForSelectedDateRangeSelector,
  unscheduledAppointmentsSelector } from 'selectors/mealPlanning';
import { toggleMealPlanClearModal } from 'actions/App';
import { TRACKING, DDE } from 'constants/Tracking';
import { logEvent as logMixpanelEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import moment from "moment";
import { friendlyDate } from 'util/timestamp';
import { appointmentsInWeekFlattened } from 'util/mealPlanning';

const MealPlanClearModal = ({
  selectedAppointmentDate,
  selectedAppointmentDateRange,
  toggleMealPlanClearModal,
  logMixpanelEvent,
  logDDEEvent,
  activeTabID,
  dayAppointments,
  weekAppointments,
  unscheduledAppointments,
  deleteAppointments,
}) => {

  function closeModal() {
    toggleMealPlanClearModal(false);
  }

  function clearMealPlan() {
    let cleared = 0;

    const weekFlattened = appointmentsInWeekFlattened(weekAppointments);

    switch (activeTabID) {
      case MEAL_PLANNER_TAB_IDS.DAY:
        cleared = dayAppointments.length;
        deleteAppointments(dayAppointments);
        break;
      case MEAL_PLANNER_TAB_IDS.WEEK:
        cleared = weekFlattened.length;
        deleteAppointments(weekFlattened);
        break;

      case MEAL_PLANNER_TAB_IDS.UNSCHEDULED:
        cleared = unscheduledAppointments.length;
        deleteAppointments(unscheduledAppointments);
        break;
    }

    logMixpanelEvent(TRACKING.MEAL_PLAN_CLEAR_ALL, {
      'Items Cleared from Meal Plan': cleared,
    });
    logDDEEvent(DDE.MEAL_PLAN_CLEAR_ALL);
    toggleMealPlanClearModal(false);
  }

  let description;
  const formattedStartDate = moment(selectedAppointmentDateRange?.start)?.format("MMM D");
  const formattedEndDate = moment(selectedAppointmentDateRange?.end)?.format("MMM D");

  const dateText = activeTabID === MEAL_PLANNER_TAB_IDS.DAY
    ? friendlyDate(selectedAppointmentDate)
    : formattedStartDate + " - " + formattedEndDate;

  const dateElement = <span className="date-range pst-p-regular">{dateText}</span>;

  switch (activeTabID) {
    case MEAL_PLANNER_TAB_IDS.DAY:
      description = <span>{`Are you sure you want to clear all recipes for`} {dateElement} {`from your Meal Plan?`}</span>;
      break;
    case MEAL_PLANNER_TAB_IDS.WEEK:
      description = <span>{`Are you sure you want to clear all recipes for`} {dateElement} {`from your Meal Plan?`}</span>;
      break;
    case MEAL_PLANNER_TAB_IDS.UNSCHEDULED:
      description = 'Are you sure you want to clear all unscheduled recipes?';
      break;
  }

  return (
    <div className="meal-plan-clear-modal">
      <div className="clear-modal background-light">
        <div className="clear-modal-wrapper">
          <div className="modal-title h4-text font-bold primary-dark">{'Clear Recipes'}</div>
          <div className="modal-description pst-p-regular">{description}</div>
          <div className="action-tray flex">
            <Button className="clear-meals btn-primary" onClick={clearMealPlan} label={'Clear'} contained={true}/>
            <Button className="close-modal btn-secondary" onClick={closeModal} label={'Cancel'} contained={true} outline={true}/>
          </div>
          <Icon className="close-x-modal greyscale-3 cursor-pointer" iconName="xLarge" onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};

MealPlanClearModal.propTypes = {
  toggleMealPlanClearModal: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    activeTabID: state.mealPlanning.activeTabID,
    meals: mealItemsSelector(state),
    selectedAppointmentDate: selectedAppointmentDateSelector(state),
    selectedAppointmentDateRange: selectedAppointmentDateRangeSelector(state),
    dayAppointments: appointmentsForSelectedDateSelector(state),
    weekAppointments: appointmentsForSelectedDateRangeSelector(state),
    unscheduledAppointments: unscheduledAppointmentsSelector(state),
  };
};

const mapDispatchToProps = {
  logMixpanelEvent,
  logDDEEvent,
  toggleMealPlanClearModal,
  deleteAppointments,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanClearModal);
