import React, { Component } from 'react';

import Button from 'components/Button';
import Icon from 'components/Pasta/Icon';
import { connect } from 'react-redux';
import { logEvent } from 'actions/mixpanel';
import { showTooManyRecipesAlert } from 'actions/MealPlanning';
import { TRACKING } from 'constants/Tracking';
import { bindInstance } from 'util/dev';

const CONFIRM_TEXT = `I'm Interested`;
const REJECT_TEXT = 'Cancel';

class TooManyRecipesAlert extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  componentDidMount() {
    this.props.logEvent(TRACKING.PROMPT_VIEW, {
      'Prompt Type': 'meal_planner',
      'Prompt Name': 'mealplanner_collection',
    });
  }

  confirmClick() {
    this.props.logEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': 'meal_planner',
      'Prompt Name': 'mealplanner_collection',
      'Prompt Label': CONFIRM_TEXT,
    });

    this.props.showTooManyRecipesAlert(false);
  }

  rejectClick() {
    this.props.logEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': 'meal_planner',
      'Prompt Name': 'mealplanner_collection',
      'Prompt Label': REJECT_TEXT,
    });

    this.props.showTooManyRecipesAlert(false);
  }

  closeModal() {
    this.props.showTooManyRecipesAlert(false);
  }

  render() {
    return (
      <div className="too-many-recipes-alert">
        <div className="modal-title h4-text font-bold primary-dark">{`You're adding more than 20 recipes!`}</div>
        <div className="modal-description p3-text greyscale-2">{`We currently only support adding 20 recipes or less at a time - let us know below if you'd like to add more to your meal plan at once!`}</div>
        <div className="action-tray">
          <Button className="confirm btn-primary" onClick={this.confirmClick}>{CONFIRM_TEXT}</Button>
          <Button className="close-modal btn-secondary" onClick={this.rejectClick}>{REJECT_TEXT}</Button>
        </div>
        <Icon className="close-x-modal greyscale-3 cursor-pointer" iconName="xLarge" onClick={this.closeModal} />
      </div>
    );
  }
}

TooManyRecipesAlert.propTypes = {
  logEvent: YummlyPropTypes.action,
  showTooManyRecipesAlert: YummlyPropTypes.action,
};

const mapDispatchToProps = {
  logEvent,
  showTooManyRecipesAlert,
};

export default connect(null, mapDispatchToProps)(TooManyRecipesAlert);
