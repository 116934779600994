import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStringFunction } from 'util/localStrings';
import { bindInstance } from 'util/dev';
import Button from 'components/Button';
import { icons } from 'constants/FontFaceCharacters';
import { clearShoppingModal, clearShoppingList, getShoppingListScreenType } from 'actions/ShoppingList';
import { logEvent } from 'actions/mixpanel';
import { TRACKING, DDE } from 'constants/Tracking';
import { logDDEEvent } from 'actions/DDE';

const str = getStringFunction(
  [
    {
      id: `shoppingListClearModal.title`,
      defaultMessage: 'Are you sure you want to remove all items from your list?',
    },
    {
      id: `shoppingListClearModal.confirm`,
      defaultMessage: 'Yes',
    },
  ],
  'shoppingListClearModal',
);

class ShoppingListClearModal extends Component {
  constructor(props) {
    super(props);

    bindInstance(this);
  }

  componentDidMount() {
    this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
      'Prompt Type': TRACKING.CONFIRM_LIST_DELETE,
    });
  }

  onConfirmClick() {
    const {
      clearType,
      clearShoppingList,
      clearShoppingModal,
      logMixpanelEvent,
      logDDEEvent,
      viewType,
    } = this.props;

    clearShoppingList();
    logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Action': TRACKING.CLEAR_ALL,
      'Prompt Type': TRACKING.CONFIRM_LIST_DELETE,
    });

    logMixpanelEvent(TRACKING.SHOPPING_LIST_MGMT, {
      'Clear List': clearType,
      'Shopping List Screen Type': getShoppingListScreenType(viewType),
    });

    logDDEEvent(DDE.SHOPPING_LIST_CLEAR_ALL);
    clearShoppingModal();
  }

  closeModal() {
    this.props.clearShoppingModal();
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
      'Prompt Type': TRACKING.CONFIRM_LIST_DELETE,
    });
  }

  render() {
    return (
      <div className="shopping-list-modal modal-clear-list">
        <div className="modal-body-wrapper">
          <h3 className="modal-title h4-text">{str(`title`)}</h3>
          <Button className="btn-primary confirm-btn" onClick={this.onConfirmClick}>
            { str(`confirm`) }
          </Button>
        </div>
        <span className="y-icon close-btn" data-icon={icons.xLarge} role="button" onClick={this.closeModal}/>
      </div>
    );
  }
}

ShoppingListClearModal.propTypes = {
  clearShoppingList: YummlyPropTypes.action,
  clearShoppingModal: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  logDDEEvent: YummlyPropTypes.action,
  viewType: YummlyPropTypes.string,
  clearType: YummlyPropTypes.string, // this is a tracking property
};

const mapStateToProps = (state) => {
  return {
    viewType: state.shoppingList.viewType,
  };
};

const mapDispatchToProps = {
  clearShoppingModal,
  clearShoppingList,
  logMixpanelEvent: logEvent,
  logDDEEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListClearModal);
